import React, {useEffect, useState} from 'react';
import {PageHeader} from "../../components";
import './components/CauseDetailComponent/customStyle.css'
import {Link, Outlet, useLocation} from 'react-router-dom'

const DonationLayout = () => {
    const location = useLocation()
    const toCapitalCase = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const breadcrumbItems = [
        {
            title: 'Home',
            route: '/',
            isLast: false
        },
        {
            title: 'Donations',
            route: '/',
            isLast: true
        },
        {
            title: toCapitalCase(location.pathname.split("/")[2]),
            route: '/',
            isLast: true
        }
    ]
    return (
        <>
            <PageHeader breadcrumbItems={breadcrumbItems} title="Donations"/>
            <section
                className="our-overview-area pos-rel  wow fadeInUp2  animated my-5"
                data-wow-delay=".1s"
                style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp2"
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <ul className="nav nav-tabs nav-tabs-02 gap-3" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <Link
                                        to="/donations/oneTime"
                                        className={`nav-link theme_btn ${location.pathname === '/donations/oneTime' ? 'active' : ''}`}
                                        id="oneTime-tab"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls="oneTime"
                                        aria-selected={location.pathname === '/donations/oneTime'}
                                    >
                                        One Time
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="/donations/monthly"
                                        className={`nav-link theme_btn ${location.pathname === '/donations/monthly' ? 'active' : ''}`}
                                        id="monthly-tab"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls="monthly"
                                        aria-selected={location.pathname === '/donations/monthly'}
                                    >
                                        Monthly
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="/donations/zakat"
                                        className={`nav-link theme_btn ${location.pathname === '/donations/zakat' ? 'active' : ''}`}
                                        id="zakat-tab"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls="zakat"
                                        aria-selected={location.pathname === '/donations/zakat'}
                                    >
                                        Zakat/Fitra
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="/donations/qurbani"
                                        className={`nav-link theme_btn ${location.pathname === '/donations/qurbani' ? 'active' : ''}`}
                                        id="qurbani-tab"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls="qurbani"
                                        aria-selected={location.pathname === '/donations/qurbani'}
                                    >
                                        Udhiya/Qurbani
                                    </Link>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <Link*/}
                                {/*        to="/donations/qurbani"*/}
                                {/*        className={`nav-link theme_btn ${location.pathname === '/donations/qurbani' ? 'active' : ''}`}*/}
                                {/*        id="qurbani-tab"*/}
                                {/*        data-toggle="tab"*/}
                                {/*        role="tab"*/}
                                {/*        aria-controls="qurbani"*/}
                                {/*        aria-selected={location.pathname === '/donations/qurbani'}*/}
                                {/*    >*/}
                                {/*        Special Request*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div
                                    className='tab-pane fade show active'
                                    id="oneTime"
                                    role="tabpanel"
                                    aria-labelledby="oneTime-tab"
                                >
                                    <section
                                        className="project-image-text-area pt-35 pb-90 wow fadeInUp"
                                        data-wow-delay=".3s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.3s",
                                            animationName: "fadeInUp"
                                        }}
                                    >
                                        <div className="container">
                                            <Outlet/>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DonationLayout;