import React, {useEffect} from 'react';
import {PageNotFountErrorImage} from "../../utils/Images";
import {Link} from "react-router-dom";

const PageNotFound = () => {

    useEffect(() => {
        document.title = "Page Not Found | Ummah relief"
    }, [])

    return (
        <div className="container-fluid my-5 text-center">
            <img src={PageNotFountErrorImage} alt="" style={{ width: '100%' }}/>
            <Link to="/" className="btn btn-primary">Go Back to Home</Link>
        </div>
    );
};

export default PageNotFound;