import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import CausesService from "../../services/causes.service";
import {PageHeader} from "../../components";
import {CauseDetailBody} from "./components/CauseDetailComponent";


const CauseDetail = () => {
    const params = useParams()
    const [cause, setCause] = useState({})

    const getCauseDetails = () => {
        CausesService.getSingleCause(params.causeID)
            .then(res => {
                // console.log(res)
                setCause(res)
            })
    }
    useEffect(() => {
        document.title = "Cause Details | Ummah relief"
        getCauseDetails()
    }, [])

    const breadcrumbItems = [
        {
            title: 'Home',
            route: '/',
            isLast: false
        },
        {
            title: 'Causes',
            route: `/causes/1`,
            isLast: false
        },
        {
            title: cause.title,
            route: `/causes/details/${cause.id}`,
            isLast: true
        }
    ]
    return (
        <>
            <PageHeader breadcrumbItems={breadcrumbItems} title={cause.title ? cause.title : 'Loading...'}/>
            <CauseDetailBody cause={cause}/>
        </>
    );
};

export default CauseDetail;