import API from "../utils/api";

const donationType = () => {
    return API.get('/donation/types')
        .then(response => {
            return response.data
        })
}

const getQurbaniOptions = () => {
    return API.get('/qurbanies')
        .then(response => {
            return response.data
        })
}

const getZakatNissab = () => {
    return API.get('/zakat_nissab')
        .then(response => {
            return response.data
        })
}

const getFitraNissab = () => {
    return API.get('/fitra_nissab')
        .then(response => {
            return response.data
        })
}

const submitDonation = (data) => {
    return API.post('/payments', data)
        .then(response => {
            return response.data
        })
}

const DonationService = {
    donationType,
    getQurbaniOptions,
    getZakatNissab,
    getFitraNissab,
    submitDonation
};

export default DonationService;