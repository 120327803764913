import React, {useEffect, useState} from 'react';
import {PageHeader} from "../../components";
import {useParams} from "react-router-dom";
import BlogsService from "../../services/blogs.service";
import {DetailComponent} from "./components/BlogDetailComponent";
import BlogService from "../../services/blogs.service";

const BlogDetail = () => {
    const params = useParams()
    const [blog, setBlog] = useState({})
    const [categories, setCategories] = useState([])
    const [recentBlog, setRecentBlog] = useState({})
    const [relatedBlogs, setRelatedBlogs] = useState([])

    const getCategories = () => {
        BlogService.getCategories()
            .then(data => {
                setCategories(data)
            })
    }

    const getAllBlogs = () => {
        setRelatedBlogs([])
        setRecentBlog({})
        BlogService.getBlogs(1)
            .then(res => {
                // console.log(res)
                if (res.data) {
                    let blogs = []
                    res.data.map((blog, index) => {
                        if (index <= 1) {
                            blogs.push(blog)
                        }
                    })
                    res.data.map((blog, index) => {
                        if (index === 2) {
                            setRecentBlog(blog)
                        }
                    })
                    setRelatedBlogs(blogs)
                } else {
                    setRelatedBlogs([])
                }
            })
    }

    const getBlogDetails = () => {
        BlogsService.getSingleBlog(params.blogID)
            .then(res => {
                setBlog(res)
            })
    }
    useEffect(() => {
        document.title = "Blog Details | Ummah relief"
        getBlogDetails()
        getCategories()
        getAllBlogs()
    }, [])

    const breadcrumbItems = [
        {
            title: 'Home',
            route: '/',
            isLast: false
        },
        {
            title: 'Blogs',
            route: `/blogs/1`,
            isLast: false
        },
        {
            title: blog.title ? blog.title : 'Loading...',
            route: '',
            isLast: true
        }
    ]
    return (
        <>
            <PageHeader breadcrumbItems={breadcrumbItems} title={blog.title ? blog.title : 'Loading...'}/>
            <DetailComponent blog={blog} categories={categories} relatedBlogs={relatedBlogs} recentBlog={recentBlog}/>
        </>
    );
};

export default BlogDetail;