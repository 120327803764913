import React, { useEffect, useState } from "react";
import { SpecialRequestForm, ZakatForm } from "./components/DonationComponent";
import PersonalInfoForm from "./components/PersonalInfoForm";
import CreditCardForm from "./components/CreditCardForm";
import DonationService from "../../services/donation.service";
import FormValidator from "../../utils/validation";
import Swal from "sweetalert2";
import ErrorFormat from "../../utils/ErrorFormatting";
import { DonnationPoster } from "../../utils/Images";


const ZakatDonation = () => {
  const [specialRequest, setSpecialRequest] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDonation, setTotalDonation] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({});
  const [creditCardInfo, setCreditCardInfo] = useState({});
  const [finalDonationData, setFinalDonationData] = useState({});
  const [zakaDonation, setZakaDonation] = useState({});
  const [zakatNissab, setZakatNissab] = useState({});
  const [fitraNissab, setFitraNissab] = useState({});
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currency] = useState("usd");

  const handleSum = (sum) => {
    setTotalAmount(sum);
  };

  const getZakatNissab = () => {
    DonationService.getZakatNissab().then((res) => {
      setZakatNissab(res);
    });
  };

  const getFitraNissab = () => {
    DonationService.getFitraNissab().then((res) => {
      setFitraNissab(res);
    });
  };

  const getDonationType = () => {
    DonationService.donationType().then((res) => {
      setSpecialRequest(res.filter((item) => item.is_special !== 0));
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    DonationService.submitDonation(finalDonationData).then((data) => {
      if (data.payment.status && data.email.status) {
        let errorExists;
        setIsLoading(false);
        if (!data.donor.status) errorExists = true;
        else if (!data.cc_details.status) errorExists = true;
        else errorExists = !data.zakat_fitra.status;

        Swal.fire(
          "Thank you for your donation!",
          `${
            errorExists
              ? "Some information cannot saved successfully but donation done successfully, if it concern do contact Ummah Relief. A verification email has been send to you with details"
              : "A confirmation email has been sent to the email provided."
          }`,
          "success"
        ).then((r) => window.location.reload());
      } else {
        let errorMessages;
        setIsLoading(false);
        if (!data.payment.status) errorMessages = data.payment.message;
        else if (!data.email.status) errorMessages = data.email.message;

        Swal.fire({
          icon: "error",
          title: "Error occurred",
          html: ErrorFormat.validationErrors(errorMessages),
        });
      }

      // if (data.payment.status && data.email.status && data.donor.status && data.cc_details.status && data.zakat_fitra.status) {
      //     Swal.fire(
      //         'Thanks for helping!',
      //         'An email has been sent to you, thanks for your help by helping humanity!',
      //         'success'
      //     ).then(r => window.location.reload())
      // } else {
      //     let errorMessages;
      //     if (!data.payment.status)
      //         errorMessages = data.payment.message
      //     else if(!data.email.status)
      //         errorMessages = data.email.message
      //     Swal.fire({
      //         icon: 'error',
      //         title: 'Error occurred',
      //         html: ErrorFormat.validationErrors(errorMessages),
      //     })
      // }
    });
  };

  useEffect(() => {
    document.title = "Zakat Donations | Ummah relief";
    getDonationType();
    getZakatNissab();
    getFitraNissab();
  }, []);

  useEffect(() => {
    setFinalDonationData({
      personal: personalInfo,
      card: creditCardInfo,
      donations: totalDonation,
      donation_of: {
        oneTime: false,
        monthly: false,
        zakat_fitra_donation: true,
        qurbani: false,
        cause: false,
        special: totalDonation.length > 0,
      },
      zakat_fitra: zakaDonation,
      payment_through: "stripe",
      currency: currency,
      duration_id: 1,
    });
    if (
      isValidated(personalInfo) &&
      isValidated(creditCardInfo) &&
      isValidated(zakaDonation)
    ) {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
    // console.log(finalDonationData)
  }, [zakaDonation, totalDonation, personalInfo, creditCardInfo, currency]);

  const isValidated = (values) => {
    if (typeof values === "object") {
      return Object.values(values).every(
        (x) => x !== null || x !== "" || x !== 0
      );
    } else {
      return values !== "" || values !== null;
    }
  };

  return (
    <div className="row">
      <div className="col-xl-8 col-lg-7 col-md-12">
        <SpecialRequestForm
          options={specialRequest}
          handleSum={(sum) => handleSum(sum)}
          setTotalDonation={(total) => setTotalDonation(total)}
          totalDonation={totalDonation}
          totalAmount={totalAmount}
        />
        <ZakatForm
          zakaDonation={zakaDonation}
          setZakaDonation={(donation) => setZakaDonation(donation)}
          zakatNissab={zakatNissab}
          fitraNissab={fitraNissab}
        />
      </div>
      <div className="col-xl-4 col-lg-4 col-md-12">
        <PersonalInfoForm
          personalInfo={personalInfo}
          setPersonalInfo={(info) => setPersonalInfo(info)}
        />
        <CreditCardForm
          creditCardInfo={creditCardInfo}
          setCreditCardInfo={(creditCard) => setCreditCardInfo(creditCard)}
        />
        <div className="d-flex justify-content-end gap-3">
          {/*<button className="btn btn-secondary btn-lg" style={{ borderRadius:30 }}>Reset</button>*/}
          <button
            className="btn btn-success btn-lg"
            disabled={isLoading}
            style={{ borderRadius: 30 }}
            onClick={() => handleSubmit()}
          >
            {isLoading ? "Processing..." : "Donate Now"}
          </button>
        </div>
        <div className="w-100 py-3">
          <img
            src={DonnationPoster}
            className="w-100"
            alt="Ummah Relief Donation"
          />
          <p className="text-dark">
            Ummah Relief International is a U.S. based non-profit organization
            established in 1994. Tax ID# 36-3954960, Not for Profit 501(c)3. All
            your donations are tax deductible.
          </p>
          <p className="text-muted">
            * Ummah Relief International may use its discretion to redirect
            donations in an emergency to where the need is greatest
          </p>
        </div>
      </div>
    </div>
  );
};

export default ZakatDonation;
