import React, {useEffect} from 'react';
import {PageHeader} from "../../components";
import './components/CauseDetailComponent/customStyle.css'
import {ContactDetailComponent, ContactForm, ContactMap} from "./components/ContactComponent";

const breadcrumbItems = [
    {
        title: 'Home',
        route: '/',
        isLast: false
    },
    {
        title: 'Contact',
        route: '/',
        isLast: true
    }
]

const Contact = () => {

    useEffect(() => {
        document.title = "Contact | Ummah relief"
    }, [])

    return (
        <>
            <PageHeader breadcrumbItems={breadcrumbItems} title="Contact Us"/>
            <ContactDetailComponent/>
            <ContactForm/>
            <ContactMap/>
        </>
    );
};

export default Contact;