import React, {useEffect, useState} from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
} from 'reactstrap';
import {Link} from "react-router-dom";
import CauseService from "../../../../services/causes.service";
import {LogoLoader} from "../../../../utils/Images";


function CarouselComponent(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [items, setItems] = useState([])
    const getAllCauses = () => {
        CauseService.getCausesForHome()
            .then(res => {
                setItems(res)
            })
    }
    useEffect(() => {
        getAllCauses()
    }, [])

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const loader = [
        {
            src: LogoLoader,
            altText: 'Loader',
            caption: 'Please Wait',
            title: 'Loading...',
            key: 1,
        }
    ];

    // document.getElementsByClassName('carousel-caption')[0].classList.remove('d-none')

    const captionText = (caption, id) => {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center gap-4">
                <span style={{lineHeight: 1}}>{caption}</span>
                <Link to={id !== 0 ? `/causes/details/${id}` : '/donations'}
                      className="text-uppercase btn btn-outline-light fw-bold px-4 carousel-btn border-0">
                    Donate Now
                </Link>
            </div>
        )
    }

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            {...props}
            // style={{height: '60vh', width: '100vw'}}
        >
            <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {
                items.length > 0
                    ?
                    items.map((item, index) => {
                        return (
                            <CarouselItem
                                onExiting={() => setAnimating(true)}
                                onExited={() => setAnimating(false)}
                                key={index}

                            >
                                {/*<img src={`data:image/png;base64, ${item.src}`} alt={item.altText}*/}
                                {/*     style={{height: '60vh', width: '100%'}}/>*/}
                                <img
                                    src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/cause/${item.image}`}
                                    alt={item.altText}
                                    style={{height: '60vh', width: '100%'}}/>
                                <div className="carousel-caption">
                                    <h3>{item.title}</h3>
                                    {captionText(item.caption, item.id)}
                                </div>
                                {/*<CarouselCaption*/}
                                {/*    captionText={captionText(item.caption, item.id)}*/}
                                {/*    captionHeader={item.title}*/}
                                {/*/>*/}

                            </CarouselItem>
                        );
                    })
                    : loader.map((item, index) => {
                        return (
                            <CarouselItem
                                onExiting={() => setAnimating(true)}
                                onExited={() => setAnimating(false)}
                                key={index}

                            >
                                <img src={item.src} alt={item.altText}
                                     style={{height: '500px', width: '500px'}}/>
                                <CarouselCaption
                                    captionText={captionText(item.caption, 0)}
                                    captionHeader={item.title}
                                />

                            </CarouselItem>
                        );
                    })
            }
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
        </Carousel>
    );
}

export default CarouselComponent;