import React, {useEffect, useState} from 'react';
import ContactService from "../../../../services/contact.service";
import FormValidator from "../../../../utils/validation";
import Swal from "sweetalert2";
import ErrorFormat from "../../../../utils/ErrorFormatting";

const ContactForm = () => {
    const [type, setType] = useState("Inquiry");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [responseMessage, setResponseMessage] = useState({})
    const [errorMessage, setErrorMessage] = useState({})

    const handleSubmit = () => {
        const data = {
            type: type,
            subject: subject,
            message: message,
            name: name,
            email: email,
            phone_number: phone,
        };
        ContactService.submitQuery(data)
            .then(data => {
                if (data.status) {
                    Swal.fire(
                        'Thanks for helping!',
                        `${data.message}`,
                        'success'
                    ).then(r => window.location.reload())
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error occurred',
                        html: ErrorFormat.validationErrors(data.message),
                    })
                }
            })
    }

    const reset = () => {
        setName("")
        setSubject("")
        setMessage("")
        setEmail("")
        setPhone("")
        setDisableSubmit(true)
        setErrorMessage({})
    }

    useEffect(() => {
        if (type !== "" && errorMessage.subject !== "" && errorMessage.name !== "" && errorMessage.email !== "" && errorMessage.phone !== "" && errorMessage.message !== "") {
            setDisableSubmit(true)
        } else {
            setDisableSubmit(false)
        }
    }, [type, subject, message, name, email, phone])

    return (
        <section
            className="contact-details-area pb-120 wow fadeInUp2  animated"
            data-wow-delay=".3s"
            style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp2"
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="post-form-area contact-form pt-125">
                            {Object.keys(responseMessage).length > 0
                                ? responseMessage.success
                                    ? <div className="alert alert-success" role="alert">{responseMessage.message}</div>
                                    : <div className="alert alert-danger" role="alert">{responseMessage.message}</div>
                                : <></>}
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="input-box">
                                        <h5>Your Full Name <span className="text-danger">*</span></h5>
                                        <div className="input-text mb-35">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={name}
                                                onBlur={(e) => setErrorMessage(old => {
                                                    return {
                                                        ...old,
                                                        name: FormValidator.isEmpty(e.target.value, e.target.id)
                                                    }
                                                })}
                                                onChange={(e) => setName(e.target.value.replace(/^\d+$/, ""))}
                                                className="form-control"
                                                placeholder="Full Name Here"
                                            />
                                            {errorMessage.name !== "" &&
                                                <small className="text-danger">{errorMessage.name}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="input-box mb-35">
                                        <h5>Your Email Address <span className="text-danger">*</span></h5>
                                        <div className="input-text input-mail">
                                            <input
                                                type="text"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onBlur={(e) => setErrorMessage(old => {
                                                    return {
                                                        ...old,
                                                        email: FormValidator.email(e.target.value, e.target.id)
                                                    }
                                                })}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="form-control"
                                                placeholder="Email Here"
                                            />
                                            {errorMessage.email !== "" &&
                                                <small className="text-danger">{errorMessage.email}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="input-box mb-35">
                                        <h5>Phone Number <span className="text-danger">*</span></h5>
                                        <div className="input-text input-phone">
                                            <input
                                                type="tel"
                                                id="phone"
                                                name="phone"
                                                onBlur={(e) => setErrorMessage(old => {
                                                    return {
                                                        ...old,
                                                        phone: FormValidator.isEmpty(e.target.value, e.target.id)
                                                    }
                                                })}
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                className="form-control"
                                                placeholder="Write Your Phone Number"
                                            />
                                            {errorMessage.phone !== "" &&
                                                <small className="text-danger">{errorMessage.phone}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="input-box mb-35">
                                        <h5>Subject <span className="text-danger">*</span></h5>
                                        <div className="input-text input-sub">
                                            <input
                                                type="text"
                                                value={subject}
                                                id="subject"
                                                name="subject"
                                                onBlur={(e) => setErrorMessage(old => {
                                                    return {
                                                        ...old,
                                                        subject: FormValidator.isEmpty(e.target.value, e.target.id)
                                                    }
                                                })}
                                                onChange={(e) => setSubject(e.target.value)}
                                                className="form-control"
                                                placeholder="I Would Like To"
                                            />
                                            {errorMessage.subject !== "" &&
                                                <small className="text-danger">{errorMessage.subject}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="input-box mb-35">
                                        <h5>Leave A Message <span className="text-danger">*</span></h5>
                                        <div className="input-text input-message">
                                          <textarea
                                              name="message"
                                              id="message"
                                              cols={30}
                                              rows={10}
                                              placeholder="Write Your Message"
                                              defaultValue={""}
                                              onBlur={(e) => setErrorMessage(old => {
                                                  return {
                                                      ...old,
                                                      message: FormValidator.isEmpty(e.target.value, e.target.id)
                                                  }
                                              })}
                                              value={message}
                                              onChange={(e) => setMessage(e.target.value)}
                                          />
                                            {errorMessage.message !== "" &&
                                                <small className="text-danger">{errorMessage.message}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-6">
                                    <div className="msg-btn text-md-center">
                                        <button
                                            className={`theme_btn theme_btn_bg border-0 ${disableSubmit && 'disable-li'}`}
                                            onClick={() => handleSubmit()}>
                                            send message <i className="fas fa-arrow-right"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default ContactForm;