import React from 'react';
import {Link} from "react-router-dom";
import {themeConfig} from "../../../../config/theme.config";

const HistorySeparation = () => {
    return (
        <div className="my-5" style={{ backgroundColor: themeConfig.green }}>
            <div className="container text-center text-white py-5">
                <div className="mb-5">
                    <h2 className="text-white">
                        Since <strong>1994</strong> to Everyday, we help people relieve some of the suffering in the world.
                    </h2>
                </div>

                <Link to="/donations/oneTime" className="text-uppercase btn btn-light text-success fw-bold px-4" style={{borderRadius: '20px', borderWidth: '2px', fontSize: '12px'}}>
                    Donate Now
                </Link>
            </div>
        </div>
    );
};

export default HistorySeparation;