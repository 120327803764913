import React, {useEffect, useState} from 'react';
import {PageHeader} from "../../components";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import CausesService from "../../services/causes.service";
import PersonalInfoForm from "./components/PersonalInfoForm";
import CreditCardForm from "./components/CreditCardForm";
import DonationService from "../../services/donation.service";
import Swal from "sweetalert2";
import ErrorFormat from "../../utils/ErrorFormatting";

const CauseDonation = () => {

    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [cause, setCause] = useState({})
    const [creditCardInfo, setCreditCardInfo] = useState({})
    const [personalInfo, setPersonalInfo] = useState({})
    const [finalDonationData, setFinalDonationData] = useState({})
    const [currency] = useState("usd")
    const [disableSubmitButton, setDisableSubmitButton] = useState(true)

    const [amount, setAmount] = useState(location.state.amount)
    const [isShowOnDescription, setIsShowOnDescription] = useState(true)


    const getCauseDetails = () => {
        CausesService.getSingleCause(params.causeID)
            .then(res => {
                setCause(res)
            })
    }

    useEffect(() => {
        document.title = "Cause Donation | Ummah relief"
        getCauseDetails()
    }, [params])

    const breadcrumbItems = [
        {
            title: 'Home',
            route: '/',
            isLast: false
        },
        {
            title: 'Causes',
            route: '/causes/1',
            isLast: false
        },
        {
            title: 'Donations',
            route: '/donations/oneTime',
            isLast: false
        },
        {
            title: cause.title,
            route: '/',
            isLast: true
        }
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        DonationService.submitDonation(finalDonationData)
            .then(data => {
                if (data.payment.status && data.email.status && data.donor.status && data.cc_details.status && data.cause_donation.status) {
                    Swal.fire(
                        'Thanks for helping!',
                        'An email has been sent to you, thanks for your help by helping humanity!',
                        'success'
                    ).then(r => window.location.reload())
                } else {
                    // setErrors(data.payment.message)
                    Swal.fire({
                        icon: 'error',
                        title: 'Error occurred',
                        html: ErrorFormat.validationErrors(data.payment.message),
                    })
                }
            })
    }

    useEffect(() => {
        setFinalDonationData({
            personal: personalInfo,
            card: creditCardInfo,
            donation_of: {
                oneTime: false,
                monthly: false,
                zakat_fitra_donation: false,
                qurbani: false,
                cause: true,
                special: false
            },
            payment_through: 'stripe',
            cause_donation: {
                cause_id: params.causeID,
                cause_title: location.state.cause.cause_title,
                amount: amount,
                is_show_on_description: isShowOnDescription
            },
            currency: currency
        })
        // console.log(isValidated(personalInfo), isValidated(creditCardInfo), isValidated(finalDonationData.cause_donation))
        if (isValidated(personalInfo) && isValidated(creditCardInfo) && isValidated(finalDonationData.cause_donation)) {
            setDisableSubmitButton(false)
        } else {
            setDisableSubmitButton(true)
        }
    }, [creditCardInfo, personalInfo, isShowOnDescription, amount])

    const isValidated = (values) => {
        if (typeof values === 'object') {
            return Object.values(values).every(x => (x !== null || x !== '' || x !== 0))
        } else {
            return (values !== "" || values !== null)
        }
    }

    return (
        <>
            <PageHeader breadcrumbItems={breadcrumbItems} title={cause.title}/>
            <div className="container py-5">
                <h3 className="text-center">{cause.title} Donation</h3>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="row col-12">
                        <div className="col-md-6">
                            <PersonalInfoForm personalInfo={personalInfo}
                                              setPersonalInfo={(info) => setPersonalInfo(info)}/>
                        </div>
                        <div className="col-md-6">
                            <h4>Donation Information</h4>
                            <hr/>
                            <div className="form-group mb-3">
                                <label htmlFor="amount" className="form-label text-dark">Donation Amount <span
                                    className="text-danger">*</span></label>
                                <div className="input-group">
                                    <button className="btn btn-secondary fw-bold" disabled={true}>$</button>
                                    <input type="number" name="amount" id="amount" value={amount}
                                           onChange={(e) => setAmount(e.target.value)} className="form-control"
                                           placeholder="Amount *"/>
                                </div>
                            </div>
                            <div className="form-check mb-3">
                                <input className="form-check-input" name="is_show_on_description" type="checkbox"
                                       onChange={(e) => setIsShowOnDescription(e.target.checked)}
                                       defaultChecked={isShowOnDescription} id="is_show_on_description"/>
                                <label className="form-check-label text-dark" htmlFor="is_show_on_description">
                                    Do you want to display it on website cause description?
                                </label>
                            </div>
                            <CreditCardForm creditCardInfo={creditCardInfo}
                                            setCreditCardInfo={(creditCard) => setCreditCardInfo(creditCard)}/>
                            <div className="d-flex justify-content-end align-items-center gap-3">
                                <button type="submit" className="btn btn-primary" style={{borderRadius: 30}}
                                        disabled={disableSubmitButton}>
                                    Submit
                                </button>
                                <button type="reset" className="btn btn-info" style={{borderRadius: 30}}
                                        onClick={() => window.location.reload()}>
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-secondary" style={{borderRadius: 30}}
                                        onClick={() => navigate(`/causes/details/${params.causeID}`, {replace: true})}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default CauseDonation;
