import React, { useEffect, useState } from "react";
import {
  QurbaniForm,
  SpecialRequestForm,
} from "./components/DonationComponent";
import PersonalInfoForm from "./components/PersonalInfoForm";
import CreditCardForm from "./components/CreditCardForm";
import DonationService from "../../services/donation.service";
import Swal from "sweetalert2";
import ErrorFormat from "../../utils/ErrorFormatting";
import { DonnationPoster } from "../../utils/Images";

const QurbaniDonation = () => {
  const [specialRequest, setSpecialRequest] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQurbaniAmount, setTotalQurbaniAmount] = useState(0);
  const [totalDonationAmount, setTotalDonationAmount] = useState(0);
  const [personalInfo, setPersonalInfo] = useState({});
  const [creditCardInfo, setCreditCardInfo] = useState({});
  const [finalDonationData, setFinalDonationData] = useState({});
  const [qurbaniDonation, setQurbaniDonation] = useState([]);
  const [totalDonation, setTotalDonation] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currency] = useState("usd");

  //   const handleSum = (sum) => {
  //     setTotalAmount(sum);
  //   };

  const CalculateQurbaniAmount = () => {
    let totalSum = 0;

    for (let i = 0; i < qurbaniDonation.length; i++) {
      //   console.log(qurbaniDonation[i].total);
      totalSum += qurbaniDonation[i].total;
    }

    setTotalQurbaniAmount(totalSum);
  };

  //   console.log("qurbaniDonation", totalQurbaniAmount);

  useEffect(() => {
    CalculateQurbaniAmount();
  }, [qurbaniDonation]);

  const CalculateDonationAmount = () => {
    let totalSum = 0;

    for (let i = 0; i < totalDonation.length; i++) {
      // console.log(totalDonation[i].amount);
      totalSum += totalDonation[i].amount;
    }

    setTotalDonationAmount(totalSum);
  };

  useEffect(() => {
    CalculateDonationAmount();
  }, [totalDonation]);

  const SumUpTotalAmount = () => {
    setTotalAmount(totalDonationAmount + totalQurbaniAmount);
  };

  useEffect(() => {
    SumUpTotalAmount();
  }, [totalDonationAmount, totalQurbaniAmount]);

  const getDonationType = () => {
    DonationService.donationType().then((res) => {
      setSpecialRequest(res.filter((item) => item.is_special !== 0));
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    DonationService.submitDonation(finalDonationData).then((data) => {
      if (data.payment.status && data.email.status) {
        let errorExists;
        setIsLoading(false);
        if (!data.donor.status) errorExists = true;
        else if (!data.cc_details.status) errorExists = true;
        else errorExists = !data.qurbani.status;

        Swal.fire(
          "Thank you for your donation!",
          `${
            errorExists
              ? "Some information cannot saved successfully but donation done successfully, if it concern do contact Ummah Relief. A verification email has been send to you with details"
              : "A confirmation email has been sent to the email provided."
          }`,
          "success"
        ).then((r) => window.location.reload());
      } else {
        setIsLoading(false);
        let errorMessages;
        if (!data.payment.status) errorMessages = data.payment.message;
        else if (!data.email.status) errorMessages = data.email.message;

        Swal.fire({
          icon: "error",
          title: "Error occurred",
          html: ErrorFormat.validationErrors(errorMessages),
        });
      }
    });
  };

  useEffect(() => {
    document.title = "Qurbani Donations | Ummah relief";
    getDonationType();
  }, []);

  useEffect(() => {
    setFinalDonationData({
      personal: personalInfo,
      card: creditCardInfo,
      donations: totalDonation,
      qurbanies: qurbaniDonation,
      donation_of: {
        oneTime: false,
        monthly: false,
        zakat_fitra_donation: false,
        qurbani: true,
        cause: false,
        special: totalDonation.length > 0,
      },
      payment_through: "stripe",
      currency: currency,
      is_special_request: "0",
      duration_id: 1,
    });
  }, [qurbaniDonation, totalDonation, personalInfo, creditCardInfo, currency]);

  useEffect(() => {
    // console.log(isValidated(personalInfo))
    if (
      isValidated(personalInfo) &&
      isValidated(creditCardInfo) &&
      isValidated(qurbaniDonation)
    ) {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
  }, [qurbaniDonation]);

  const isValidated = (values) => {
    if (typeof values === "object") {
      if (Array.isArray(values)) {
        // console.log("from array", values)
        return values.length > 0;
      } else {
        // console.log("from object", Object.values(values))
        return Object.values(values).every(
          (x) => x !== null || x !== "" || x !== 0
        );
      }
    } else {
      return values !== "" || values !== null;
    }
  };

  //   console.log(qurbaniDonation)

  return (
    <div className="row">
      <div className="col-xl-8 col-lg-7 col-md-12">
        <SpecialRequestForm
          options={specialRequest}
          //   handleSum={(sum) => setTotalAmount(sum)}
          setTotalDonation={(total) => setTotalDonation(total)}
          totalDonation={totalDonation}
          totalAmount={totalAmount}
        />
        <QurbaniForm
          qurbaniDonation={qurbaniDonation}
          //   setTotalAmount={(sum) => setTotalAmount(sum)}
          totalAmount={totalAmount}
          setQurbaniDonation={(donation) => setQurbaniDonation(donation)}
        />
        <h4 className="fw-bold">Total: ${totalAmount}</h4>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-12">
        <PersonalInfoForm
          personalInfo={personalInfo}
          setPersonalInfo={(info) => setPersonalInfo(info)}
        />
        <CreditCardForm
          creditCardInfo={creditCardInfo}
          setCreditCardInfo={(creditCard) => setCreditCardInfo(creditCard)}
        />
        <div className="d-flex justify-content-end gap-3">
          {/*<button className="btn btn-secondary btn-lg" style={{ borderRadius: 30 }}>Reset</button>*/}
          <button
            className="btn btn-success btn-lg"
            disabled={isLoading}
            style={{ borderRadius: 30 }}
            onClick={() => handleSubmit(1)}
          >
            {isLoading ? "Processing..." : "Donate Now"}
          </button>
        </div>
        <div className="w-100 py-3">
          <img
            src={DonnationPoster}
            className="w-100"
            alt="Ummah Relief Donation"
          />
          <p className="text-dark">
            Ummah Relief International is a U.S. based non-profit organization
            established in 1994. Tax ID# 36-3954960, Not for Profit 501(c)3. All
            your donations are tax deductible.
          </p>
          <p className="text-muted">
            * Ummah Relief International may use its discretion to redirect
            donations in an emergency to where the need is greatest
          </p>
        </div>
      </div>
    </div>
  );
};

export default QurbaniDonation;
