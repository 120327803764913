import React from 'react';

const ContactDetailComponent = () => {
    return (
        <section className="contact-box-area pb-80 pt-125">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 offset-xl-1">
                        <div
                            className="section-title text-center mb-85 wow fadeInUp2  animated"
                            data-wow-delay=".1s"
                            style={{
                                visibility: "visible",
                                animationDelay: "0.1s",
                                animationName: "fadeInUp2"
                            }}
                        >
                            <h6 className="left-line pl-75 pr-75">Contact Us</h6>
                            <h2>
                                We've Take A Mission To <br/>
                                <span>Save the Humanity</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 text-center text-md-start">
                        <div
                            className="contact-box d-lg-flex mb-50 wow fadeInUp2  animated"
                            data-wow-delay=".1s"
                            style={{
                                visibility: "visible",
                                animationDelay: "0.1s",
                                animationName: "fadeInUp2"
                            }}
                        >
                            <div className="contact-box__icon">
                                <i className="fas fa-map-marker-alt"/>
                            </div>
                            <div className="contact-box__content">
                                <h4>Locations</h4>
                                <h5>311 Heine Ave, Elgin, IL 60123, USA</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 text-center text-md-start">
                        <div
                            className="contact-box d-lg-flex mb-50 wow fadeInUp2  animated"
                            data-wow-delay=".3s"
                            style={{
                                visibility: "visible",
                                animationDelay: "0.3s",
                                animationName: "fadeInUp2"
                            }}
                        >
                            <div className="contact-box__icon">
                                <i className="fas fa-envelope"/>
                            </div>
                            <div className="contact-box__content">
                                <h4>Email Us</h4>
                                <h5>ummah@ummahrelief.org</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 text-center text-md-start">
                        <div
                            className="contact-box d-lg-flex mb-50 wow fadeInUp2  animated"
                            data-wow-delay=".5s"
                            style={{
                                visibility: "visible",
                                animationDelay: "0.5s",
                                animationName: "fadeInUp2"
                            }}
                        >
                            <div className="contact-box__icon">
                                <i className="fas fa-phone"/>
                            </div>
                            <div className="contact-box__content">
                                <h4>Phone Us</h4>
                                <h5>1-800-713-4482 <br/> 847-622-0574</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactDetailComponent;