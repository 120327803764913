import React, {useEffect, useState} from 'react';
import {Link, NavLink, useParams} from "react-router-dom";
import {DonationBg} from "../../../../utils/Images";
import moment from "moment";
import CausesService from "../../../../services/causes.service";

const CauseDetailNavTabs = ({cause}) => {
    const [tabActive, setTabActive] = useState({description: true})
    const [firstLatestCause, setFirstLatestCause] = useState({})
    const [secondLatestCause, setSecondLatestCause] = useState({})
    const [causeDonations, setCauseDonations] = useState([])
    const params = useParams()
    const handleTabActive = (tab) => {
        if (tab === 'description') {
            setTabActive({description: true})
        } else if (tab === 'updates') {
            setTabActive({updates: true})
        } else if (tab === 'donations') {
            setTabActive({donations: true})
        } else {
            setTabActive({reviews: true})
        }
    }

    const getRecentLatestCauses = () => {
        let number = 0
        CausesService.getCauses(1)
            .then(res => {
                res.data.map((item, index) => {
                    if (item.id !== parseInt(params.causeID)) {
                        if (number === 0) {
                            // console.log(index)
                            setFirstLatestCause(item)
                        }
                        if (number === 1) {

                            setSecondLatestCause(item)
                        }
                        number++
                    }

                })
            })
    }

    const getCauseDonation = () => {
        CausesService.getDonationByCauseID(params.causeID)
            .then(data => {
                setCauseDonations(data)
            })
    }

    useEffect(() => {
        getRecentLatestCauses()
        getCauseDonation()
    }, [])

    return (
        <section
            className="our-overview-area pos-rel  wow fadeInUp2  animated my-5"
            data-wow-delay=".1s"
            style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp2"
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">

                        {/*     Tabs buttons        */}
                        <ul className="nav nav-tabs nav-tabs-02 gap-3" id="myTab" role="tablist">
                            <li className="nav-item">
                                <button
                                    className={`nav-link theme_btn ${tabActive.description ? 'active' : ''}`}
                                    id="home-tab"
                                    data-toggle="tab"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected={tabActive.description}
                                    onClick={() => handleTabActive('description')}
                                >
                                    Description
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link theme_btn ${tabActive.updates ? 'active' : ''}`}
                                    id="profile-tab"
                                    data-toggle="tab"
                                    role="tab"
                                    aria-controls="updates"
                                    aria-selected={tabActive.updates}
                                    onClick={() => handleTabActive('updates')}
                                >
                                    Updates
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link theme_btn ${tabActive.donations ? 'active' : ''}`}
                                    id="contact-tab"
                                    data-toggle="tab"
                                    role="tab"
                                    aria-controls="donations"
                                    aria-selected={tabActive.donations}
                                    onClick={() => handleTabActive('donations')}
                                >
                                    Donations
                                </button>
                            </li>
                        </ul>

                        {/*     Tabs Contents       */}
                        <div className="tab-content" id="myTabContent">

                            {/*     Descriptions    */}
                            <div
                                className={`tab-pane fade ${tabActive.description ? 'show active' : ''}`}
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <section
                                    className="project-image-text-area pt-35 pb-90 wow fadeInUp"
                                    data-wow-delay=".3s"
                                    style={{
                                        visibility: "visible",
                                        animationDelay: "0.3s",
                                        animationName: "fadeInUp"
                                    }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-8 col-lg-8 col-md-12">
                                                <div dangerouslySetInnerHTML={{__html: cause.content}}></div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-12">
                                                <div className="image-content-right">
                                                    <div className="widget grey-bg mb-30">
                                                        <div className="widget-rewards">
                                                            <h4>Related Cause</h4>
                                                            {Object.keys(firstLatestCause).length > 0
                                                                ? <>
                                                                    <div className="reaward__thumb text-center mb-25">
                                                                        {/*<img*/}
                                                                        {/*    src={`data:image/png;base64, ${firstLatestCause.image_base64}`}*/}
                                                                        {/*    alt=""/>*/}
                                                                        <img
                                                                            src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/cause/${firstLatestCause.image}`}
                                                                            alt=""/>
                                                                    </div>
                                                                    <h5>
                                                                        {firstLatestCause.title}
                                                                    </h5>
                                                                    <p className="mb-20">
                                                                        {firstLatestCause.short_desc}
                                                                    </p>

                                                                    <ul className="rewards-list pt-15 mb-25 p-0">
                                                                        <li>
                                                                            <i className="fas fa-user-circle"/>
                                                                            <strong>Goals: </strong>${firstLatestCause.goal}
                                                                        </li>
                                                                        <li>
                                                                            <i className="fas fa-trophy"/>
                                                                            <strong>Raised: </strong>${firstLatestCause.raised}
                                                                        </li>
                                                                    </ul>
                                                                    <a
                                                                        className="theme_btn theme_btn_bg"
                                                                        href={`/causes/details/${firstLatestCause.id}`}
                                                                        data-animation="fadeInLeft"
                                                                        data-delay=".5s"
                                                                    >
                                                                        Donate <i className="fas fa-arrow-right"/>
                                                                    </a>
                                                                </>
                                                                : <></>}

                                                        </div>
                                                    </div>
                                                    <div className="widget">
                                                        <div
                                                            className="widget-donate-box pos-rel text-center"
                                                            style={{
                                                                backgroundImage: `url(${DonationBg})`
                                                            }}
                                                        >
                                                            <h5>Donate Now</h5>
                                                            <h3>Want To Donate</h3>
                                                            <Link
                                                                className="theme_btn theme_btn_bg"
                                                                to="/donations/oneTime"
                                                                data-animation="fadeInLeft"
                                                                data-delay=".5s"
                                                            >
                                                                donate now <i className="fas fa-arrow-right"/>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            {/*     Updates     */}
                            <div
                                className={`tab-pane fade ${tabActive.updates ? 'show active' : ''}`}
                                id="updates"
                                role="tabpanel"
                                aria-labelledby="updates-tab"
                            >
                                <section className="project-image-text-area pb-90 pt-25">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-8 col-lg-7 col-md-12">
                                                <ul className="timeline mb-100">
                                                    {moment(cause.created_at).format('MM/DD/YYYY') !== moment(cause.updated_at).format('MM/DD/YYYY')
                                                        ? <li
                                                            className="timeline-list wow fadeInUp2"
                                                            data-wow-delay=".1s"
                                                            style={{
                                                                visibility: "visible",
                                                                animationDelay: "0.1s",
                                                                animationName: "fadeInUp2"
                                                            }}
                                                        >
                                                            <div className="update-content">
                                                                <div className="update-meta">
                                                              <span>
                                                                  <i className="far fa-calendar-alt"/>
                                                                  {moment(cause.updated_at).fromNow(true)}
                                                                </span> (
                                                                    <span>{moment(cause.updated_at).format('MM/DD/YYYY')}</span>)
                                                                </div>
                                                                <h4 className="left-line">Causes updated</h4>
                                                                <p>
                                                                    The cause is updated with some modification
                                                                </p>
                                                            </div>
                                                        </li>
                                                        : <></>}
                                                    <li
                                                        className="timeline-list wow fadeInUp2 animated animated"
                                                        data-wow-delay=".1s"
                                                        style={{
                                                            visibility: "visible",
                                                            animationDelay: "0.1s",
                                                            animationName: "fadeInUp2"
                                                        }}
                                                    >
                                                        <div className="update-content">
                                                            <div className="update-meta">
                                                                <span>
                                                                  <i className="far fa-calendar-alt"/>
                                                                    {moment(cause.created_at).fromNow(true)}
                                                                </span> (
                                                                <span>{moment(cause.created_at).format('MM/DD/YYYY')}</span>)
                                                            </div>
                                                            <h4 className="left-line">
                                                                Causes Posted
                                                            </h4>
                                                            <p>
                                                                This cause is posted by the admin
                                                            </p>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-12">
                                                <div className="image-content-right">
                                                    <div className="widget grey-bg mb-30">
                                                        <div className="widget-rewards">
                                                            <h4>Related Cause</h4>
                                                            {
                                                                Object.keys(firstLatestCause).length > 0
                                                                    ? <>
                                                                        <div className="reaward__thumb text-center mb-25">
                                                                            <img
                                                                                src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/cause/${secondLatestCause.image}`}
                                                                                alt=""/>
                                                                        </div>
                                                                        <h5>
                                                                            {secondLatestCause.title}
                                                                        </h5>
                                                                        <p className="mb-20">
                                                                            {secondLatestCause.short_desc}
                                                                        </p>

                                                                        <ul className="rewards-list pt-15 mb-25 p-0">
                                                                            <li>
                                                                                <i className="fas fa-user-circle"/>
                                                                                <strong>Goals: </strong>${secondLatestCause.goal ? secondLatestCause.goal : 0}
                                                                            </li>
                                                                            <li>
                                                                                <i className="fas fa-trophy"/>
                                                                                <strong>Raised: </strong>${secondLatestCause.raised ? secondLatestCause.raised : 0}
                                                                            </li>
                                                                        </ul>
                                                                        <NavLink
                                                                            className="theme_btn theme_btn_bg"
                                                                            href={`/causes/details/${secondLatestCause.id}`}
                                                                            data-animation="fadeInLeft"
                                                                            data-delay=".5s"
                                                                        >
                                                                            Donate <i className="fas fa-arrow-right"/>
                                                                        </NavLink>
                                                                    </>
                                                                    : <></>}

                                                        </div>
                                                    </div>
                                                    <div className="widget">
                                                        <div
                                                            className="widget-donate-box pos-rel text-center"
                                                            style={{
                                                                backgroundImage: `url(${DonationBg})`
                                                            }}
                                                        >
                                                            <h5>Donate Now</h5>
                                                            <h3>Want To Donate</h3>
                                                            <Link
                                                                className="theme_btn theme_btn_bg"
                                                                to="/donations/oneTime"
                                                                data-animation="fadeInLeft"
                                                                data-delay=".5s"
                                                            >
                                                                donate now <i className="fas fa-arrow-right"/>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            {/*     Donations   */}
                            <div
                                className={`tab-pane fade ${tabActive.donations ? 'show active' : ''}`}
                                id="donations"
                                role="tabpanel"
                                aria-labelledby="donations-tab"
                            >
                                <small className="text-info">The list of donation that are of those who are welling to show it in the
                                    cause descriptions at the time of donation submission</small>
                                <div className="backer-list-table pt-45 pb-130 table-responsive">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Donate Amount</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {causeDonations && causeDonations.map((donation, index) => (
                                            <tr>
                                                <td>{donation.first_name + ' ' + donation.last_name}</td>
                                                <td>${donation.amount}</td>
                                                <td>{moment(donation.created_at).format('MM/DD/YYYY')}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default CauseDetailNavTabs;