import React, {useState} from 'react';
import {themeConfig} from "../config/theme.config";
import FormValidator from "../utils/validation";
import SubscriptionService from "../services/subscription.service";

const WebsiteFooter = () => {
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const handleSubscription = (e) => {
        e.preventDefault()
        SubscriptionService.addSubscription(email)
            .then(data => {
                setMessage(data.message)
                setEmail("")
            })
    }
    return (
        <footer className="text-white" style={{backgroundColor: themeConfig.dark}}>
            <div className="container">
                <footer className="py-5">
                    <div className="row">

                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                            <h5 className="text-uppercase mb-4 text-white">UMMAH RELIEF INTERNATIONAL</h5>
                            <p className="text-white">
                                The best way you can support Ummah Relief International is to make a financial
                                contribution. A financial contribution enables Ummah Relief International to help those
                                in need every day of the year where and when help is needed most.
                            </p>
                        </div>

                        <div className="col-12 col-sm-3 col-md-3 col-lg-3 mb-3">
                            <h5 className="text-uppercase mb-4 text-white">CONTACT US</h5>
                            <ul className="nav flex-column text-white">
                                <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-center">
                                    <i className="fas fa-location-pin"></i>
                                    P.O. Box 1426 Elgin, IL 60121 USA
                                </li>
                                <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-center">
                                    <i className="fas fa-envelope"></i>
                                    Ummah@ummahrelief.org
                                </li>
                                <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-center">
                                    <i className="fas fa-phone"></i>
                                    1-800-713-4482
                                </li>
                                <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-center">
                                    <i className="fas fa-mobile"></i>
                                    847-622-0574
                                </li>
                                <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-center">
                                    <i className="fas fa-fax"></i>
                                    847-741-3816
                                </li>
                            </ul>
                        </div>

                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                            <form onSubmit={(e) => handleSubscription(e)}>
                                <h5 className="mb-3 text-white">NEWSLETTER SIGN UP</h5>
                                <div className="w-100">
                                    {message !== "" && <div className="alert alert-info" role="alert">{message}</div>}
                                    <label htmlFor="subscription_email" className="visually-hidden">Email
                                        address</label>
                                    <div className="input-group">
                                        <input id="subscription_email" type="email" className="form-control"
                                               value={email}
                                               onChange={(e) => setEmail(e.target.value)}
                                               onBlur={(e) => setError(FormValidator.email(e.target.value, e.target.id))}
                                               placeholder="Email address" spellCheck="false" data-ms-editor="true"/>
                                        <button className="btn btn-primary"
                                                disabled={email === "" || error !== ""} type="submit">Subscribe
                                        </button>
                                    </div>
                                    {error !== "" && <small className="text-danger">{error}</small>}

                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between py-4 my-4 border-top">
                        <p className="text-white">COPYRIGHT © 2022. ALL RIGHTS RESERVED.</p>
                        <ul className="list-unstyled d-flex">
                            <li className="ms-3">
                                <a className="link-dark" href="#">
                                    <i className="fab fa-twitter text-white" style={{fontSize: 20}}></i>
                                </a>
                            </li>
                            <li className="ms-3">
                                <a className="link-dark" href="#">
                                    <i className="fab fa-instagram text-white" style={{fontSize: 20}}></i>
                                </a>
                            </li>
                            <li className="ms-3">
                                <a className="link-dark" href="#">
                                    <i className="fab fa-facebook text-white" style={{fontSize: 20}}></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </footer>
            </div>
        </footer>
    );
};

export default WebsiteFooter;