import React from 'react';
import CauseTopCard from "./CauseTopCard";
import CauseDetailNavTabs from "./CauseDetailNavTabs";

const CauseDetailBody = ({cause}) => {
    return (
        <>
            <CauseTopCard cause={cause}/>
            <CauseDetailNavTabs cause={cause}/>
        </>
        // <div className="container my-5">
        //     <div className="row">
        //         <CauseTopCard cause={cause}/>
        //             <CauseDetailNavTabs />
        //         <div className="col-lg-8">
        //         </div>
        //         <div className="col-lg-4">
        //
        //         </div>
        //     </div>
        // </div>
    );
};

export default CauseDetailBody;