import React from 'react';
import {Link} from "react-router-dom";
import {themeConfig} from "../../../../config/theme.config";

const DonatNowSeparation = () => {
    return (
        <div className="my-5" style={{ backgroundColor: themeConfig.green }}>
            <div className="container d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-md-between justify-content-sm-center align-items-center text-white py-4">
                <div>
                    <h2 className="text-uppercase fw-bold text-white">Your Help is Needed</h2>
                    <p className="text-white">Donate your zakah and sadaqah May Allah reward you for your help</p>
                </div>
                <div>
                    <Link to="/donations/oneTime" className="text-uppercase btn btn-light text-success fw-bold px-4" style={{borderRadius: '20px', borderWidth: '2px', fontSize: '12px'}}>
                        Donate Now
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default DonatNowSeparation;