import React from 'react';
import {Card, CardBody} from "reactstrap";
import {themeConfig} from "../../../../config/theme.config";
import {HandHoldingDollar, Zakat, Home, WaterTap, HelpingHand, MoneyBag} from "../../../../utils/Images";

const OurMission = () => {
    return (
        <div className="container my-5">
            <h2 className="text-center fw-bold mb-5">Our Mission</h2>
            <div className="row">
                <div className="col-lg-4 col-sm-12 mb-4">
                    <Card className="border-0 rounded-0">
                        <CardBody>
                            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-start gap-5 py-3 px-2">
                                <div className="py-3 px-4 text-white"
                                     style={{backgroundColor: themeConfig.green, borderBottomRightRadius: '50%'}}>
                                    <img src={HandHoldingDollar} alt="" width={40}/>
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <h4 className="fw-bold">Charity for Education</h4>
                                    <p>We provide a number of services and supplies such as books, school supplies,
                                        meals and student’s preventive health care services.</p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-4 col-sm-12 mb-4">
                    <Card className="border-0 rounded-0">
                        <CardBody>
                            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-start gap-5 py-3 px-2">
                                <div className="py-3 px-4 text-white"
                                     style={{backgroundColor: themeConfig.green, borderBottomRightRadius: '50%'}}>
                                    <img src={Zakat} alt="" width={40}/>
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <h4 className="fw-bold">Feed for Hungry Child</h4>
                                    <p>Feed the Children exists to end childhood hunger. Around the world, we provide
                                        nourishing meals every day to more than thousands of children.</p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-4 col-sm-12 mb-4">
                    <Card className="border-0 rounded-0">
                        <CardBody>
                            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-start gap-5 py-3 px-2">
                                <div className="py-3 px-4 text-white"
                                     style={{backgroundColor: themeConfig.green, borderBottomRightRadius: '50%'}}>
                                    <img src={Home} alt="" width={40}/>
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <h4 className="fw-bold">Home for Homeless</h4>
                                    <p>The project is part of a national movement of tiny-house villages, an alternative
                                        approach to housing the homeless.</p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-4 col-sm-12 mb-4">
                    <Card className="border-0 rounded-0">
                        <CardBody>
                            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-start gap-5 py-3 px-2">
                                <div className="py-3 px-4 text-white"
                                     style={{backgroundColor: themeConfig.green, borderBottomRightRadius: '50%'}}>
                                    <img src={WaterTap} alt="" width={40}/>
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <h4 className="fw-bold">Bringing Clean Water</h4>
                                    <p>Providing a reliable and safe water source will unlock potential by returning
                                        time for study, work, and imagination.</p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-4 col-sm-12 mb-4">
                    <Card className="border-0 rounded-0">
                        <CardBody>
                            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-start gap-5 py-3 px-2">
                                <div className="py-3 px-4 text-white"
                                     style={{backgroundColor: themeConfig.green, borderBottomRightRadius: '50%'}}>
                                    <img src={HelpingHand} alt="" width={40}/>
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <h4 className="fw-bold">Help Little Hands</h4>
                                    <p>We believe the child is special. No matter their additional needs or disability,
                                        is capable of being outstanding.</p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-4 col-sm-12 mb-4">
                    <Card className="border-0 rounded-0">
                        <CardBody>
                            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-start gap-5 py-3 px-2">
                                <div className="py-3 px-4 text-white"
                                     style={{backgroundColor: themeConfig.green, borderBottomRightRadius: '50%'}}>
                                    <img src={MoneyBag} alt="" width={40}/>
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <h4 className="fw-bold">Donate for Children</h4>
                                    <p>To transform communities through helping children, youth and their families who
                                        need support.</p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default OurMission;