import React, {useEffect, useRef, useState} from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import {WebsiteLogo} from "../utils/Images";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";

const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus()
    }

    return [htmlElRef, setFocus]
}

const WebsiteHeader = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMenuFixed, setIsMenuFixed] = useState(false);
    const [searchInputShow, setSearchInputShow] = useState(false)
    const [keywords, setKeywords] = useState("")
    const [inputRef, setInputFocus] = useFocus()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const toggleTopFixed = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setIsMenuFixed(true)
        } else if (scrolled <= 300) {
            setIsMenuFixed(false)
        }
    };

    window.addEventListener('scroll', toggleTopFixed, {passive: true});

    document.addEventListener('keydown', function (event) {
        if (event.key === "Escape") {
            setSearchInputShow(false)
        }
    });

    const toggle = () => {
        setIsOpen(!isOpen)
        setInputFocus()
    }

    useEffect(() => {
        setSearchInputShow(false)
        setKeywords("")
    }, [location.pathname])

    return (
        <div>
            <div className="py-2 border-bottom">
                <div className="container">
                    <div className="fw-bold" style={{fontSize: '12px'}}>
                        Call: 1-800 713-4482 | Email: ummah@ummahrelief.org
                    </div>
                </div>
            </div>
            <Navbar {...props} color="white" className="border-bottom border-success border-4" light expand="md"
                    container={true} fixed={isMenuFixed ? 'top' : ''}>
                <NavbarBrand style={{cursor: 'pointer'}} onClick={() => navigate('/')}>
                    <img src={WebsiteLogo} alt="" width={302} height={86}/>
                </NavbarBrand>
                <NavbarToggler onClick={toggle}/>
                <Collapse isOpen={isOpen} navbar style={{justifyContent: "end"}}>
                    <Nav className="mr-auto" navbar hidden={searchInputShow}>
                        <NavItem className="mx-3 d-flex align-items-center">
                            <Link to="/about"
                                  className={`text-uppercase text-dark text-decoration-none ${location.pathname === '/about' && 'fw-bold'}`}>
                                About
                            </Link>
                        </NavItem>
                        <NavItem className="mx-3 d-flex align-items-center">
                            <Link to="/causes/1"
                                  className={`text-uppercase text-dark text-decoration-none ${location.pathname === `/causes/${params && params.pageNo ? params.pageNo : 'details/' + params.causeID}` && 'fw-bold'}`}>
                                Causes
                            </Link>
                        </NavItem>
                        <NavItem className="mx-3 d-flex align-items-center">
                            <Link to="/donations/oneTime"
                                  className={`text-uppercase text-dark text-decoration-none ${
                                      (location.pathname === '/donations/oneTime' ||
                                          location.pathname === '/donations/monthly' ||
                                          location.pathname === '/donations/zakat' ||
                                          location.pathname === '/donations/qurbani')
                                      && 'fw-bold'}`}>
                                Donation /Calculator
                            </Link>
                        </NavItem>
                        <NavItem className="mx-3 d-flex align-items-center">
                            <Link to="/blogs/1"
                                  className={`text-uppercase text-dark text-decoration-none ${location.pathname === `/blogs/${params && params.pageNo ? params.pageNo : 'details/' + params.blogID}` && 'fw-bold'}`}>Blog</Link>
                        </NavItem>
                        {/*<NavItem className="mx-3 d-flex align-items-center">*/}
                        {/*    <Link to="/gallery"*/}
                        {/*          className={`text-uppercase text-dark text-decoration-none ${location.pathname === '/gallery' && 'fw-bold'}`}>*/}
                        {/*        Gallery*/}
                        {/*    </Link>*/}
                        {/*</NavItem>*/}
                        <NavItem className="mx-3 d-flex align-items-center">
                            <Link to="/contact"
                                  className={`text-uppercase text-dark text-decoration-none ${location.pathname === '/contact' && 'fw-bold'}`}>
                                Contact
                            </Link>
                        </NavItem>
                        <NavItem className="mx-3">
                            <NavLink href="#"
                                     onClick={() => setSearchInputShow(!searchInputShow)}
                                     className="text-uppercase text-dark">
                                <i className="fas fa-search"></i>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
                <div className={`container position-absolute w-full bg-white p-3`}
                     style={{zIndex: 9999, display: searchInputShow ? 'block' : 'none'}}>
                    <form onSubmit={() => navigate(`/search/keywords/${keywords}`)}>
                        <div className="input-group" style={{borderRadius: 30}}>
                            <input
                                type="text"
                                className="form-control p-2 px-4"
                                value={keywords}
                                ref={inputRef}
                                onChange={(e) => setKeywords(e.target.value)}
                                style={{borderTopLeftRadius: 30, borderBottomLeftRadius: 30}}
                                autoFocus={true}
                                placeholder="Search keyword for cause or blog"/>
                            <button
                                className="btn btn-success"
                                disabled={keywords === ""}
                                type="submit"
                                // onClick={() => navigate(`/search/keywords/${keywords}`)}
                                style={{borderRadius: 0}}>
                                <i className="fas fa-search"></i>
                            </button>
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => {
                                    setSearchInputShow(false)
                                    setKeywords("")
                                }}
                                style={{borderTopRightRadius: 30, borderBottomRightRadius: 30}}
                            >
                                <i className="fas fa-close"></i>
                            </button>
                        </div>
                    </form>

                </div>
            </Navbar>

        </div>
    );
}

export default WebsiteHeader;