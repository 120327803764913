import React, {useState} from 'react';
import {WebsiteFooter, WebsiteHeader} from "../components";
import {Outlet} from 'react-router-dom'
import {FaArrowCircleUp} from 'react-icons/fa';
import {Button} from "./Styles";

const WebsiteLayout = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 200) {
            setVisible(true)
        } else if (scrolled <= 200) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);
    return (
        <>
            <WebsiteHeader/>
            <Outlet/>
            <WebsiteFooter/>
            <Button>
                <FaArrowCircleUp
                    onClick={scrollToTop}
                    style={{display: visible ? 'inline' : 'none'}}
                />
            </Button>
        </>
    );
};

export default WebsiteLayout;