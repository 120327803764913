import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import LinesEllipsis from 'react-lines-ellipsis'

const CauseCard = ({cause}) => {
    const [progressBar, setProgressBar] = useState(0)

    useEffect(() => {
        let percentage = cause.raised / cause.goal
        if (percentage) {
            setProgressBar(percentage * 100)
        } else {
            setProgressBar(0)
        }
    }, [cause])

    function formatNum(str) {
        //remove the + sign if you want a string instead
        return +str.replace(/\./g,'').replace(/,/g,'.')
    }

    return (
        <div className="col-md-4 padding-15 mb-4">
            <div className="causes-content">
                <div className="causes-thumb">
                    <Link to={`/causes/details/${cause.id}`} className="text-decoration-none">
                        {/*<img src={`data:image/png;base64, ${cause.image_base64}`} alt="causes"/>*/}
                        <img src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/cause/${cause.image}`}
                             alt="causes"/>
                    </Link>
                    <Link to={`/causes/details/${cause.id}`} className="donate-btn text-decoration-none fw-bold"
                          style={{borderRadius: 30}}>
                        Donate Now
                        <i className="fas fa-plus"/>
                    </Link>
                </div>
                <div className="causes-details">
                    <Link to={`/causes/details/${cause.id}`} className="text-decoration-none">
                        <h4>
                            <Link to={`/causes/details/${cause.id}`} className="text-decoration-none">
                                <LinesEllipsis
                                    text={cause.title}
                                    maxLine='1'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                />
                            </Link>
                        </h4>
                    </Link>
                    <p>
                        <LinesEllipsis
                            text={cause.short_desc}
                            maxLine='1'
                            ellipsis='...'
                            trimRight
                            basedOn='letters'
                        />
                    </p>
                    <div className="donation-box">
                        {cause.goal
                            ? <p>
                                <i className="fa-solid fa-chart-simple"></i>
                                <strong>Goal:</strong> ${cause.goal ? cause.goal.toFixed(0) : 0}
                            </p>
                            : <></>}
                        <p>
                            <i className="fa-solid fa-thumbs-up"></i>
                            <strong>Raised:</strong> ${cause.raised ? cause.raised.toFixed(0) : 0}
                        </p>
                    </div>
                    {cause.goal
                        ? <div className="progress mb-3">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{width: `${progressBar}%`}}
                                aria-valuenow={progressBar}
                                aria-valuemin={0}
                                aria-valuemax={100}
                            >
                              <span
                                  className="wow cssanimation fadeInLeft"
                                  style={{visibility: "visible", animationName: "bfadeInLeft"}}
                              >
                                {progressBar > 100 ? 100 : progressBar}%
                              </span>
                            </div>
                        </div>
                        : <></>
                    }

                    <Link to={`/causes/details/${cause.id}`} className="read-more">
                        Read More
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CauseCard;