const email = (email, id) => {
  if (!email) {
    document.getElementById(id).style.borderColor = "red";
    return "Email is required";
  } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
    document.getElementById(id).style.borderColor = "red";
    return "Incorrect email format";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const password = (password, id) => {
  if (!password) {
    document.getElementById(id).style.borderColor = "red";
    return "Password is required";
  } else if (password.length < 8) {
    document.getElementById(id).style.borderColor = "red";
    return "Password must have a minimum 8 characters";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const creditCardNumber = (cardNo, id) => {
  if (!cardNo) {
    document.getElementById(id).style.borderColor = "red";
    return "Card number is required";
  } else if (cardNo.length !== 19) {
    document.getElementById(id).style.borderColor = "red";
    return "Card number is not valid";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const expiryYear = (val, id) => {
  if (!val) {
    document.getElementById(id).style.borderColor = "red";
    return "Field is required";
  } else if (val.length !== 4) {
    document.getElementById(id).style.borderColor = "red";
    return "Expiry year is incorrect";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const cardVerificationCode = (val, id) => {
  if (!val) {
    document.getElementById(id).style.borderColor = "red";
    return "Field is required";
  } else if (val.length !== 3) {
    document.getElementById(id).style.borderColor = "red";
    return "CVC is incorrect";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const isEmpty = (val, id) => {
  if (!val) {
    document.getElementById(id).style.borderColor = "red";
    return "Field is required";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const numberMaxCheck = (min, max, val) => Math.max(min, Math.min(val, max));

const maxLengthCheck = (val) => {
  if (val.target.value.length > val.target.max) {
    return val.target.value.slice(0, val.target.max);
  }
  return val.target.value;
};

const handleOnBlur = (val, setErrors) => {
  // console.log(val.target.id)
  let message = "";
  if (val.target.id === "card_number") {
    message = creditCardNumber(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, cardNoError: message };
    });
  } else if (val.target.id === "name_on_card") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, cardName: message };
    });
  } else if (val.target.id === "exp_year") {
    message = expiryYear(val.target.value, val.target.id);

    if (message === "") {
      const currentYear = new Date().getFullYear();
      if (val.target.value < currentYear) {
        message = "Credit Card is expired";
      }
    }
    setErrors((old) => {
      return { ...old, expiryYear: message };
    });
  } else if (val.target.id === "exp_month") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, expiryYear: message };
    });
  } else if (val.target.id === "csc_code") {
    message = cardVerificationCode(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, cvc: message };
    });
  } else if (val.target.id === "first_name") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, firstName: message };
    });
  } else if (val.target.id === "last_name") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, lastName: message };
    });
  } else if (val.target.id === "address") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, address: message };
    });
  } else if (val.target.id === "zip_code") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, zipCode: message };
    });
  } else if (val.target.id === "email") {
    message = email(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, email: message };
    });
  } else if (val.target.id === "phone") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, phone: message };
    });
  }
};

const requiredFieldCheck = (object, donationType) => {
  let validationArray = [];
  Object.entries(object).forEach(([key, value]) => {
    if (donationType === "oneTime" || donationType === "monthly") {
      if (key !== "qurbanies" && key !== "zakat" && key !== "cause") {
        if (!value || value === "" || value === null || value?.length === 0) {
          validationArray.push(false);
        } else {
          validationArray.push(true);
        }
      }
    } else if (donationType === "qurbani") {
      if (key !== "donations" && key !== "zakat" && key !== "cause") {
        if (!value || value === "" || value === null || value?.length === 0) {
          validationArray.push(false);
        } else {
          validationArray.push(true);
        }
      }
    } else if (donationType === "zakat") {
      if (key !== "donations" && key !== "qurbani" && key !== "cause") {
        if (!value || value === "" || value === null || value?.length === 0) {
          validationArray.push(false);
        } else {
          validationArray.push(true);
        }
      }
    } else if (donationType === "cause") {
      if (key !== "donations" && key !== "qurbani" && key !== "zakat") {
        if (!value || value === "" || value === null || value?.length === 0) {
          validationArray.push(false);
        } else {
          validationArray.push(true);
        }
      }
    }
  });
  return validationArray;
};

const FormValidator = {
  email,
  password,
  creditCardNumber,
  numberMaxCheck,
  maxLengthCheck,
  handleOnBlur,
  isEmpty,
  requiredFieldCheck,
};

export default FormValidator;
