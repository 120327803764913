import React from 'react';
import {Card, CardBody} from "reactstrap";
import {Link} from "react-router-dom";

const TrioCardComponent = () => {
    return (
        <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row">
            <Card className="border-0 w-100 rounded-0" style={{backgroundColor: '#180A04', color: '#fff'}}>
                <CardBody className="py-4">
                    <div className="d-flex flex-column flex-sm-co flex-md-row flex-lg-row justify-content-start align-items-center gap-4 py-4">
                        <div className="px-3 py-1" style={{border: '3px solid #fff', borderRadius: '50%'}}>
                            <i className="fas fa-dollar" style={{fontSize: '3.8em'}}></i>
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-start">
                            <h4 className="text-uppercase text-white">Donation</h4>
                            <p className="text-white">Organized network providing humanitarian services across the World.</p>
                            <Link to="/donations/oneTime" className="text-uppercase btn btn-outline-light fw-bold" style={{borderRadius: '20px', borderWidth: '2px', fontSize: '12px'}}>
                                Donate Now
                            </Link>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Card className="border-0 w-100 rounded-0" style={{backgroundColor: '#284E32', color: '#fff'}}>
                <CardBody className="py-4">
                    <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-4 py-4">
                        <div className="px-3 py-1">
                            {/*<i className="fas fa-dollar" style={{fontSize: '3.8em'}}></i>*/}
                            <i className="fa-solid fa-hand-holding-heart" style={{fontSize: '3.8em'}}></i>
                            {/*<i className="fas fa-hands-holding-heart" style={{fontSize: '3.8em'}}></i>*/}
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-start">
                            <h4 className="text-uppercase text-white">Cause</h4>
                            <p className="text-white">We come up with new, unique ideas to raise the bar for humanity.</p>
                            <Link to="/causes/1" className="text-uppercase btn btn-outline-light fw-bold" style={{borderRadius: '20px', borderWidth: '2px', fontSize: '12px'}}>
                                Read More
                            </Link>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Card className="border-0 w-100 rounded-0" style={{backgroundColor: '#F26622', color: '#fff'}}>
                <CardBody className="py-4">
                    <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-4 py-4">
                        <div className="px-3 py-1">
                            <i className="fa-sharp fa-solid fa-handshake" style={{fontSize: '3.8em'}}></i>
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-start">
                            <h4 className="text-uppercase text-white">Contact</h4>
                            <p className="text-white" style={{ fontSize:14 }}>Click here for questions, opportunities or ways to get involved. Together we are
                                Ummah!</p>
                            <Link to="/contact" className="text-uppercase btn btn-outline-light fw-bold" style={{borderRadius: '20px', borderWidth: '2px', fontSize: '12px'}}>
                                Contact Now
                            </Link>
                        </div>
                    </div>
                </CardBody>

            </Card>
        </div>
    );
};

export default TrioCardComponent;