import React from 'react';
import {themeConfig} from "../../../../config/theme.config";
import {Card, CardBody} from "reactstrap";
import {
    Globe,
    HandShake,
    WaterDrop,
} from "../../../../utils/Images";

const VisionGoal = () => {
    return (
        <div className="mt-5" style={{backgroundColor: themeConfig.green}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-12 my-4">
                        <Card className="border-0 rounded-0 text-white" color="transparent">
                            <CardBody>
                                <div
                                    className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                                    <div
                                        className="text-white d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                                        <img src={HandShake} alt="" width={40}/>
                                        <span className="fw-bold" style={{fontSize: 22}}>Our Commitment</span>
                                    </div>
                                    <div className="d-flex flex-column align-items-start w-100 align-items-center">
                                        <p className="text-white" style={{ textAlign: 'justify' }}>
                                            We are committed to providing food for the hungry, shelter for the homeless,
                                            and medicine for the sick. We believe it is our duty as Muslims to carry on
                                            this noble mission to help and assist needy people.
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-4">
                        <Card className="border-0 rounded-0 text-white" color="transparent">
                            <CardBody>
                                <div
                                    className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                                    <div
                                        className="text-white d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                                        <img src={WaterDrop} alt="" width={40}/>
                                        <span className="fw-bold" style={{fontSize: 22}}>Our Vision</span>
                                    </div>
                                    <div className="d-flex flex-column align-items-start w-100 align-items-center">
                                        <p className="text-white" style={{ textAlign: 'justify' }}>
                                            Our vision is to inform and educate local communities about all crises and
                                            help develop a sense of brotherhood and caring for others. Your help and
                                            support are highly appreciated.
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-4">
                        <Card className="border-0 rounded-0 text-white" color="transparent">
                            <CardBody>
                                <div
                                    className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                                    <div
                                        className="text-white d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                                        <img src={Globe} alt="" width={50}/>
                                        <span className="fw-bold"
                                              style={{fontSize: 20}}>	Countries Of Operation</span>
                                    </div>
                                    <div className="d-flex flex-column align-items-start w-100 align-items-center">
                                        <p className="text-white" style={{ textAlign: 'justify' }}>
                                            Afghanistan | Albania | Bosnia |Bangladesh | Iraq | Kashmir | Kosovo | India
                                            | Indonesia | Lebanon | Mali | Syria | Myanmar | Palestine | Pakistan |
                                            Somalia | Sudan | U.S.A.
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default VisionGoal;