import React, {useEffect, useState} from 'react';
import Select from "react-select";
import LocationService from "../../../services/location.service";
import FormValidator from "../../../utils/validation";


const PersonalInfoForm = ({personalInfo, setPersonalInfo}) => {

    // Select2 dropdown values
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [disableState, setDisableState] = useState(false)
    const [cities, setCities] = useState([])
    const [disableCity, setDisableCity] = useState(false)
    const [hearAboutUsList] = useState([
        {
            label: 'Google',
            value: 'Google'
        },
        {
            label: 'Facebook',
            value: 'Facebook'
        },
        {
            label: 'Friends',
            value: 'Friends'
        }
    ])

    const [errors, setErrors] = useState({})

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [address, setAddress] = useState("")
    const [countryID, setCountryID] = useState(0)
    const [stateID, setStateID] = useState(0)
    const [cityID, setCityID] = useState(0)
    const [zipcode, setZipcode] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [hearAboutUs, setHearAboutUs] = useState(hearAboutUsList[0].value)

    const getCountries = () => {
        LocationService.getCountries()
            .then(res => {
                const countries = res.map(country => {
                    return {
                        value: country.id,
                        label: country.name
                    }
                })
                setCountries(countries)
            })
    }

    const getStates = (e) => {
        if (e && e.value !== 0) {
            setDisableState(false)
            setCountryID(e.value)
            LocationService.getStates(e.value)
                .then(res => {
                    const states = res.map(state => {
                        return {
                            value: state.id,
                            label: state.name
                        }
                    })
                    setStates(states)
                })
        } else {
            setDisableState(true)
            setErrors(old => {
                return {...old, country: 'Field is required'}
            })
            setStates([])
        }
    }

    const getCities = (e) => {
        if (e && e.value !== 0) {
            setDisableCity(false)
            setStateID(e.value)
            LocationService.getCities(countryID, e.value)
                .then(res => {
                    const cities = res.map(city => {
                        return {
                            value: city.id,
                            label: city.name
                        }
                    })
                    setCities(cities)
                })
        } else {
            setDisableCity(true)
            setErrors(old => {
                return {...old, state: 'Field is required'}
            })
        }
    }

    const handleCityChange = (e) => {
        if (e && e.value !== 0) {
            setCityID(e.value)
        } else {
            setErrors(old => {
                return {...old, city: 'Field is required'}
            })
        }
    }

    const handleHearAboutChange = (e) => {
        if (e && e.value !== 0) {
            setHearAboutUs(e.value)
        } else {
            setErrors(old => {
                return {...old, hearAboutUs: 'Field is required'}
            })
        }
    }

    useEffect(() => {
        getCountries()
        setDisableState(true)
        setDisableCity(true)
    }, [])

    useEffect(() => {
        const obj = {
            first_name: firstName,
            last_name: lastName,
            address: address,
            country_id: countryID,
            state_id: stateID,
            city_id: cityID,
            zip_code: zipcode,
            phone: phone,
            email: email,
            hear_about_us: hearAboutUs
        }
        if (setPersonalInfo !== undefined) {
            setPersonalInfo({...obj})
        }

    }, [firstName, lastName, address, countryID, stateID, cityID, zipcode, phone, email, hearAboutUs])

    return (
        <>
            <h4>Personal Info</h4>
            <hr/>
            {/*<MaskedInput label="First Name" id="first_name" mask="9999-9999-9999-9999" onChange={(e) => setFirstName(e.target.value)} value={firstName}/>*/}
            <div className="form-group mb-3">
                <label htmlFor="first_name" className="form-label text-dark">First Name <span
                    className="text-danger">*</span></label>

                <input type="text" name="first_name" id="first_name"
                       onChange={(e) => setFirstName(e.target.value.replace(/^\d+$/, ""))} value={firstName}
                       className="form-control"
                       onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                       placeholder="First Name *"/>
                {errors.firstName !== "" && <small className="text-danger">{errors.firstName}</small>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="last_name" className="form-label text-dark">Last Name <span
                    className="text-danger">*</span></label>
                <input type="text" name="last_name" id="last_name"
                       onChange={(e) => setLastName(e.target.value.replace(/^\d+$/, ""))} value={lastName}
                       className="form-control"
                       onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                       placeholder="Last Name *"/>
                {errors.lastName !== "" && <small className="text-danger">{errors.lastName}</small>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="address" className="form-label text-dark">Address <span
                    className="text-danger">*</span></label>
                <input type="text" name="address" id="address"
                       onChange={(e) => setAddress(e.target.value)} value={address} className="form-control"
                       onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                       placeholder="Address (Street name and Unit #) *"/>
                {errors.address !== "" && <small className="text-danger">{errors.address}</small>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="country_id" className="form-label text-dark">Country <span
                    className="text-danger">*</span></label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={countries[0]}
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => getStates(e)}
                    name="country_id"
                    options={countries}
                />
                {errors.country !== "" && <small className="text-danger">{errors.country}</small>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="state_id" className="form-label text-dark">State <span
                    className="text-danger">*</span></label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={states[0]}
                    isLoading={false}
                    isDisabled={disableState}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => getCities(e)}
                    onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                    name="state_id"
                    options={states}
                />
                {errors.state !== "" && <small className="text-danger">{errors.state}</small>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="city_id" className="form-label text-dark">City <span
                    className="text-danger">*</span></label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={cities[0]}
                    isLoading={false}
                    isDisabled={disableCity}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => handleCityChange(e)}
                    name="city_id"
                    options={cities}
                />
                {errors.city !== "" && <small className="text-danger">{errors.city}</small>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="zip_code" className="form-label text-dark">Zipcode <span
                    className="text-danger">*</span></label>
                <input type="text" name="zip_code" id="zip_code"
                       onChange={(e) => setZipcode(e.target.value)} value={zipcode} className="form-control"
                       onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                       placeholder="Zipcode *"/>
                {errors.zipCode !== "" && <small className="text-danger">{errors.zipCode}</small>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="email" className="form-label text-dark">Email <span
                    className="text-danger">*</span></label>
                <input type="text" name="email" id="email" onChange={(e) => setEmail(e.target.value)} value={email}
                       onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                       className="form-control"
                       placeholder="Email *"/>
                {errors.email !== "" && <small className="text-danger">{errors.email}</small>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="phone" className="form-label text-dark">Phone <span
                    className="text-danger">*</span></label>
                <input type="number" name="phone" id="phone" onChange={(e) => setPhone(e.target.value)}
                       className="form-control" value={phone}
                       onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                       placeholder="Phone *"/>
                {errors.phone !== "" && <small className="text-danger">{errors.phone}</small>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="hear_about_us" className="form-label text-dark">How did you hear about
                    us: <span
                        className="text-danger">*</span></label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={hearAboutUsList[0]}
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => handleHearAboutChange(e)}
                    name="hear_about_us"
                    options={hearAboutUsList}
                />
                {errors.hearAboutUs !== "" && <small className="text-danger">{errors.hearAboutUs}</small>}
            </div>
        </>
    );
};

export default PersonalInfoForm;