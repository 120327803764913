import React from "react";
import { useState } from "react";
import InputMask from "react-input-mask";
import {
  MasterCard,
  UnionPayCard,
  JcbCard,
  DiscoverCard,
  AmericanExpressCard,
  VisaCard,
  UnkownCard,
} from "../utils/Images";

const MaskedInput = (props) => {
  const cardImage = () => {
    if (props.cardType == "visa") {
      return VisaCard;
    } else if (props.cardType == "master-card") {
      return MasterCard;
    } else if (props.cardType == "american-express") {
      return AmericanExpressCard;
    } else if (props.cardType == "discover") {
      return DiscoverCard;
    } else if (props.cardType == "jcb") {
      return JcbCard;
    } else if (props.cardType == "unionpay") {
      return UnionPayCard;
    } else {
      return UnkownCard;
    }
  };

  return (
    <>
      <label htmlFor={props.id} className="form-label text-dark">
        {props.label}{" "}
        {props.required ? <span className="text-danger">*</span> : <></>}
      </label>
      <InputMask
        mask={props.mask}
        value={props.value}
        onChange={props.onChange}
        onInput={props.onInput}
        onBlur={props.onBlur}
        alwaysShowMask={false}
        maskChar=""
      >
        {(inputProps) => (
          <div className="d-flex">
            <input
              className="form-control"
              id={props.id}
              min={props.min}
              max={props.max}
              {...inputProps}
            />
            {props.isValid && props.cardType && props.cardType !== null ? (
              <img
                src={cardImage()}
                alt={props.cardType}
                className="credit-card-type-input"
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </InputMask>
    </>
  );
};

export default MaskedInput;
