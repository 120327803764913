import {
    Home,
    Causes,
    About,
    PageNotFound,
    CauseDetail,
    CauseDonation,
    Blogs,
    BlogDetail,
    Contact, DonationLayout, OneTimeDonation, MonthlyDonation, ZakatDonation, QurbaniDonation, Search, Gallery
} from "../pages/website";



const WebsiteRoutes = [
    {path: '/', Component: Home},
    {path: '/causes/:pageNo', Component: Causes},
    {path: '/about', Component: About},
    {path: '/causes/details/:causeID', Component: CauseDetail},
    {path: '/causes/donation/:causeID', Component: CauseDonation},
    {
        path: '/donations',
        Component: DonationLayout,
        children: [
            {childPath: '/donations/oneTime', ChildComponent: OneTimeDonation},
            {childPath: '/donations/monthly', ChildComponent: MonthlyDonation},
            {childPath: '/donations/zakat', ChildComponent: ZakatDonation},
            {childPath: '/donations/qurbani', ChildComponent: QurbaniDonation},
        ]
    },
    {path: '/blogs/:pageNo', Component: Blogs},
    {path: '/blogs/details/:blogID', Component: BlogDetail},
    {path: '/search/:searchBy/:id', Component: Search},
    {path: '/contact', Component: Contact},
    {path: '/gallery', Component: Gallery},
    {path: '*', Component: PageNotFound},
]

export {
    WebsiteRoutes
}
