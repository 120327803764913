import React, {useEffect, useState} from 'react';

const ZakatForm = ({zakatNissab, fitraNissab, zakaDonation, setZakaDonation}) => {
    const [noOfPerson, setNoOfPerson] = useState(null)
    const [totalPayingFitra, setTotalPayingFitra] = useState(null)
    const [checkingAmount, setCheckingAmount] = useState(null);
    const [savingAccount, setSavingAccount] = useState(null);
    const [goldAmount, setGoldAmount] = useState(null);
    const [silverAmount, setSilverAmount] = useState(null);
    const [cashOnHand, setCashOnHand] = useState(null);
    const [otherIncome, setOtherIncome] = useState(null);
    const [otherCashValue, setOtherCashValue] = useState(null);
    const [calculatedTotal, setCalculatedTotal] = useState(null);
    const [zakatableAmount, setZakatableAmount] = useState(null);
    const [payableZakat, setPayableZakat] = useState(null)
    const [desirableAmountInputStatus, setDesirableAmountInputStatus] = useState(true)
    const [desirableZakatAmount, setDesirableZakatAmount] = useState(null)

    function handleNoOfPerson(e) {
        setTotalPayingFitra(0)
        setNoOfPerson(e.target.value)
        // console.log(e.target.value)
        if (e.target.value) {
            setTotalPayingFitra(parseInt(fitraNissab.nissab) * parseInt(noOfPerson))
        } else if (e.target.value===0 || e.target.value===null || e.target.value==='') {
            setTotalPayingFitra(0)
        } else {
            setTotalPayingFitra(null)
        }
    }

    useEffect(() => {
        if (noOfPerson && noOfPerson !== 0) {
            setTotalPayingFitra(parseInt(fitraNissab.nissab) * parseInt(noOfPerson))
        } else {
            setTotalPayingFitra(null)
        }
    }, [noOfPerson])

    useEffect(() => {
        if (noOfPerson && noOfPerson !== 0 && totalPayingFitra) {
            setZakaDonation({
                ...zakaDonation,
                fitra: {
                    no_of_person: noOfPerson,
                    fitra_amount: totalPayingFitra,
                    fitra_nesab_amount: fitraNissab.nissab,
                    donation_type: 'fitra'
                }
            })
        } else {
            delete zakaDonation.fitra
        }
    }, [totalPayingFitra])

    const calculateZakatParamTotal = () => {
        return (checkingAmount ? parseInt(checkingAmount) : 0)
            + (savingAccount ? parseInt(savingAccount) : 0)
            + (goldAmount ? parseInt(goldAmount) : 0)
            + (silverAmount ? parseInt(silverAmount) : 0)
            + (cashOnHand ? parseInt(cashOnHand) : 0)
            + (otherIncome ? parseInt(otherIncome) : 0)
            + (otherCashValue ? parseInt(otherCashValue) : 0)
    }

    useEffect(() => {
        setCalculatedTotal(calculateZakatParamTotal())
    }, [checkingAmount, savingAccount, goldAmount, silverAmount, cashOnHand, otherIncome, otherCashValue])

    useEffect(() => {
        if (calculatedTotal > zakatNissab.nissab) {
            setZakatableAmount(calculatedTotal)
        } else {
            setZakatableAmount(0)
        }

    }, [calculatedTotal, zakatableAmount])

    useEffect(() => {
        if (zakatableAmount > 0) {
            setPayableZakat((zakatableAmount * 0.025).toFixed(2))
        } else {
            setPayableZakat(0)
        }
    }, [zakatableAmount])

    useEffect(() => {
        if (zakatableAmount > 0) {
            // console.log(desirableZakatAmount, payableZakat)
            setZakaDonation({
                ...zakaDonation,
                zakat: {
                    total_amount: calculatedTotal,
                    zakatable_amount: zakatableAmount,
                    zakat_nesab_amount: zakatNissab.nissab,
                    zakat_paid: (desirableZakatAmount === null) ? payableZakat : desirableZakatAmount,
                    donation_type: 'zakat'
                }
            })
        }
    }, [desirableZakatAmount, zakatableAmount, payableZakat])


    return (
        <div>
            <h4 className="my-3">Zakat Calculator</h4>
            <div style={{fontSize: "16px", letterSpacing: "0.05em"}}>
                <div className="mb-3 d-flex flex-column gap-3">
                    {/*<div className="form-group d-flex justify-content-between align-items-center">*/}
                    {/*    <label htmlFor="" className="form-label text-danger fw-bold w-100">Please enter amount if you*/}
                    {/*        have already calculated zakat:</label>*/}
                    {/*    <div className="input-group w-auto">*/}
                    {/*        <button className="btn btn-secondary" disabled={true}>$</button>*/}
                    {/*        <input type="number" min="0" className="form-control"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label htmlFor="" className="form-label text-dark w-100">Checking Account:</label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input type="number" min="0" value={checkingAmount}
                                   onChange={(e) => setCheckingAmount(e.target.value)} className="form-control"/>
                        </div>
                    </div>

                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label htmlFor="" className="form-label text-dark w-100">Savings Account:</label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input type="number" value={savingAccount} min="0"
                                   onChange={(e) => setSavingAccount(e.target.value)} className="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label htmlFor="" className="form-label text-dark w-100">Jewelry-Gold:</label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input type="number" value={goldAmount} min="0"
                                   onChange={(e) => setGoldAmount(e.target.value)}
                                   className="form-control"/>
                        </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label htmlFor="" className="form-label text-dark w-100">Jewelry-Silver:</label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input type="number" value={silverAmount} min="0"
                                   onChange={(e) => setSilverAmount(e.target.value)} className="form-control"/>
                        </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label htmlFor="" className="form-label text-dark w-100">Cash on Hand:</label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input type="number" value={cashOnHand} min="0"
                                   onChange={(e) => setCashOnHand(e.target.value)}
                                   className="form-control"/>
                        </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label htmlFor="" className="form-label text-dark w-100">Other Cash Value:</label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input type="number" value={otherCashValue} min="0"
                                   onChange={(e) => setOtherCashValue(e.target.value)} className="form-control"/>
                        </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label htmlFor="" className="form-label text-dark w-100">Other Income if any:</label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input type="number" value={otherIncome} min="0"
                                   onChange={(e) => setOtherIncome(e.target.value)} className="form-control"/>
                        </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label htmlFor="" className="form-label text-dark w-100">Total:</label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input type="number" className="form-control" value={calculatedTotal} min="0"
                                   onChange={(e) => setCalculatedTotal(e.target.value)} disabled={true}/>
                        </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label htmlFor="" className="form-label text-dark w-100">Total Amount (Zakatable) in:</label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input type="number" className="form-control" value={zakatableAmount} min="0"
                                   onChange={(e) => setZakatableAmount(e.target.value)} disabled={true}/>
                        </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label htmlFor="" className="form-label text-dark w-100">Nisab:</label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input type="number" className="form-control" min="0" disabled={true}
                                   value={zakatNissab.nissab}/>
                        </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label htmlFor="" className="form-label text-primary fw-bold w-100">Zakat = Total Amount x
                            0.025:</label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input
                                type="number"
                                className="form-control"
                                disabled={true}
                                min="0"
                                value={payableZakat}
                                onChange={(e) => setPayableZakat(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <label className="quarbani-checkbx form-label fw-bold w-100">
                            <input type="checkbox"
                                   defaultChecked={false}
                                   onClick={(e) => setDesirableAmountInputStatus(!e.target.checked)}/>
                            <span className="ps-2 text-dark">
                              Enter Amount which you want to donate
                            </span>
                        </label>
                        <div className="input-group w-auto">
                            <button className="btn btn-secondary" disabled={true}>$</button>
                            <input
                                disabled={desirableAmountInputStatus}
                                type="number"
                                min="0"
                                value={desirableZakatAmount}
                                onChange={(e) => setDesirableZakatAmount(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <p
                        style={{
                            color: "var(--color-gray-variant)",
                            fontSize: "12px",
                            textAlign: "justify",
                        }}
                    >
                        If Total Amount exceeds the Nisab, pay Zakat on total cash including
                        Nisab. If you have any questions about Zakat or Nisab, please call
                        URI office or your Area Scholar.
                    </p>
                </div>

                <div>
                    <h4 className="my-3">Please also pay Fitra</h4>
                    <div style={{fontSize: "16px", letterSpacing: "0.05em"}}>
                        <div className="pb-1 d-flex flex-column flex-sm-row flex-md-row flex-lg-row gap-3">
                            <div className="form-group">
                                <label htmlFor="" className="form-label text-dark">This year fitra for one Person
                                    is</label>
                                <div className="input-group w-full">
                                    <button className="btn btn-secondary" disabled={true}>$</button>
                                    <input
                                        type="number"
                                        className="form-control"
                                        disabled={true}
                                        min="0"
                                        value={fitraNissab.nissab}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="form-label text-dark">X (No of persons)</label>
                                <input
                                    type="number"
                                    className="form-control w-100"
                                    value={noOfPerson}
                                    min="0"
                                    onInput={(e) => handleNoOfPerson(e)}
                                    // onChange={(e) => handleNoOfPerson(e)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="form-label text-dark">Calculated Fitra</label>
                                <div className="input-group w-100">
                                    <button className="btn btn-secondary" disabled={true}>$</button>
                                    <input
                                        type="number"
                                        disabled={true}
                                        min="0"
                                        value={totalPayingFitra}
                                        onChange={(e) => setTotalPayingFitra(e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ZakatForm;
