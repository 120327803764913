import React from 'react';
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import {Link, useParams} from "react-router-dom";
import _ from "lodash";

const PaginationComponent = ({route, total}) => {
    const params = useParams()
    return (
        <Pagination>
            <PaginationItem className={params.pageNo === '1' ? 'disable-li' : ''}>
                <Link to={`/${route}/1`} className="text-decoration-none">
                    <PaginationLink
                        first
                    />
                </Link>
            </PaginationItem>
            <PaginationItem className={params.pageNo === '1' ? 'disable-li' : ''}>
                <Link to={`/${route}/${parseInt(params.pageNo) - 1}`} className="text-decoration-none">
                    <PaginationLink
                        previous
                    />
                </Link>

            </PaginationItem>
            {_.times(Math.ceil(total), (i) => (
                <PaginationItem key={i}>
                    <Link to={`/${route}/${i + 1}`} className="text-decoration-none">
                        <PaginationLink className={parseInt(params.pageNo)===i+1 ? 'active' : ''}>
                            {i + 1}
                        </PaginationLink>
                    </Link>
                </PaginationItem>
            ))}
            <PaginationItem className={parseInt(params.pageNo) >= Math.ceil(total) ? 'disable-li' : ''}>
                <Link to={`/${route}/${parseInt(params.pageNo) + 1}`} className="text-decoration-none">
                    <PaginationLink
                        next
                    />
                </Link>
            </PaginationItem>
            <PaginationItem className={parseInt(params.pageNo) >= Math.ceil(total) ? 'disable-li' : ''}>
                <Link to={`/${route}/${Math.ceil(total)}`} className="text-decoration-none">
                    <PaginationLink
                        last
                    />
                </Link>
            </PaginationItem>
        </Pagination>
    );
};

export default PaginationComponent;