import React from 'react';
import {Card, CardBody} from "reactstrap";
import {Link} from "react-router-dom";
import {themeConfig} from "../../../../config/theme.config";

const DonationType = () => {
    return (
        <div className="my-5 container">
            <h2 className="text-center fw-bold mb-5">Donation Types</h2>
            <div className="row">
                <div className="col-lg-6 mb-4">
                    <Card className="border-0 rounded-0" style={{ backgroundColor: themeConfig.green }}>
                        <CardBody>
                            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-5 text-white py-3 px-2">
                                <div className="px-3 py-1 d-none d-sm-block d-md-block" style={{border: '3px solid #fff', borderRadius: '50%'}}>
                                    <i className="fas fa-dollar text-white" style={{fontSize: '3.8em'}}></i>
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <h4 className="fw-bold text-white">One Time Donation</h4>
                                    <p className="text-white">One time donation via Ummah Relief can save lives.</p>
                                    <Link to="/donations/oneTime" className="text-uppercase btn btn-outline-light fw-bold" style={{borderRadius: '20px', borderWidth: '2px', fontSize: '12px'}}>
                                        Donate Now
                                    </Link>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-6 mb-4">
                    <Card className="border-0 rounded-0" style={{ backgroundColor: themeConfig.green }}>
                        <CardBody>
                            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-5 text-white py-3 px-2">
                                <div className="px-3 py-1 d-none d-sm-block d-md-block" style={{border: '3px solid #fff', borderRadius: '50%'}}>
                                    <i className="fas fa-dollar text-white" style={{fontSize: '3.8em'}}></i>
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <h4 className="fw-bold text-white">Monthly Donation</h4>
                                    <p className="text-white">Donate monthly via Ummah Relief and help feed the hungry.</p>
                                    <Link to="/donations/monthly" className="text-uppercase btn btn-outline-light fw-bold" style={{borderRadius: '20px', borderWidth: '2px', fontSize: '12px'}}>
                                        Donate Now
                                    </Link>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-6 mb-4">
                    <Card className="border-0 rounded-0" style={{ backgroundColor: themeConfig.green }}>
                        <CardBody>
                            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-5 text-white py-3 px-2">
                                <div className="px-3 py-1 d-none d-sm-block d-md-block" style={{border: '3px solid #fff', borderRadius: '50%'}}>
                                    <i className="fas fa-dollar text-white" style={{fontSize: '3.8em'}}></i>
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <h4 className="fw-bold text-white">Qurbani</h4>
                                    <p className="text-white" style={{ fontSize: 13 }}>Give and get included with our Qurbani Online Program this year</p>
                                    <Link to="/donations/qurbani" className="text-uppercase btn btn-outline-light fw-bold" style={{borderRadius: '20px', borderWidth: '2px', fontSize: '12px'}}>
                                        Donate Now
                                    </Link>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-6 mb-4">
                    <Card>
                        <Card className="border-0 rounded-0" style={{ backgroundColor: themeConfig.green }}>
                            <CardBody>
                                <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-5 text-white py-3 px-2">
                                    <div className="px-3 py-1 d-none d-sm-block d-md-block" style={{border: '3px solid #fff', borderRadius: '50%'}}>
                                        <i className="fas fa-dollar text-white" style={{fontSize: '3.8em'}}></i>
                                    </div>
                                    <div className="d-flex flex-column justify-content-start align-items-start">
                                        <h4 className="fw-bold text-white">Zakat</h4>
                                        <p className="text-white">Help those in desperate need by paying Zakat</p>
                                        <Link to="/donations/zakat" className="text-uppercase btn btn-outline-light fw-bold" style={{borderRadius: '20px', borderWidth: '2px', fontSize: '12px'}}>
                                            Donate Now
                                        </Link>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DonationType;