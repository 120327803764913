import React from 'react';
import {WhatWeDoImage} from "../../../../utils/Images";
import {Link} from "react-router-dom";

const WhatWeDo = () => {
    return (
        <div className="container py-5">
            <div
                className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-start gap-2">
                <div className="w-100 mb-4">
                    <img src={WhatWeDoImage} style={{width: '80%'}} alt=""/>
                </div>
                <div className="w-100 mb-4">
                    <h3 className="mb-5 fw-bold">What We Do</h3>
                    <p className="fw-bold"  style={{ textAlign: 'justify' }}>
                        Please consider helping orphans, widows, senior citizens, and people who can’t work due to
                        medical disabilities and those needy people who cannot even afford to eat one meal, receive
                        medicine or to drink clean water.
                    </p>
                    <p  style={{ textAlign: 'justify' }}>
                        Each year, millions of men, women, and children are impacted by floods, earthquakes, tornadoes,
                        hurricanes and other disasters. Everyday Ummah Relief International Humanitarian Relief programs
                        help people relieve some of the suffering in the world. With your help, Ummah Relief
                        International responds to natural disasters all over the world with quick and targeted action.
                    </p>
                    <p  style={{ textAlign: 'justify' }}>
                        Ummah Relief International was established in 1994 to help needy people around the world. It is
                        a dedicated relief organization providing a variety of humanitarian services such as food,
                        clothing, medical treatment, and schools. It brings help and hope to thousands of victims of
                        natural and human disasters.
                    </p>
                    <Link to="/donations/oneTime" className="btn btn-outline-success text-uppercase fw-bold" style={{ borderRadius: 30 }}>Donate Now</Link>
                </div>
            </div>
        </div>
    );
};

export default WhatWeDo;