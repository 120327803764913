import React, {useEffect} from 'react';
import {
    AboutSection,
    CarouselComponent,
    DonationType, DonationWaysSeparator,
    DonatNowSeparation, HistorySeparation, OurMission,
    TrioCardComponent
} from "./components/HomeComponent";

const Home = () => {

    useEffect(() => {
        document.title = "Home | Ummah relief"
    }, [])

    return (
        <>
            <CarouselComponent />
            <TrioCardComponent />
            <AboutSection />
            <HistorySeparation />
            <OurMission />
            <DonatNowSeparation />
            <DonationType />
            <DonationWaysSeparator />
        </>
    );
};

export default Home;