import React, { useState, useEffect } from "react";
import {
  DonationForm,
  SpecialRequestForm,
} from "./components/DonationComponent";
import PersonalInfoForm from "./components/PersonalInfoForm";
import CreditCardForm from "./components/CreditCardForm";
import DonationService from "../../services/donation.service";
import FormValidator from "../../utils/validation";
import Swal from "sweetalert2";
import ErrorFormat from "../../utils/ErrorFormatting";
import { DonnationPoster } from "../../utils/Images";

const OneTimeDonation = () => {
  const [donationType, setDonationType] = useState([]);
  const [specialRequest, setSpecialRequest] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDonation, setTotalDonation] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({});
  const [creditCardInfo, setCreditCardInfo] = useState({});
  const [finalDonationData, setFinalDonationData] = useState({});
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currency] = useState("usd");

  // const handleSum = (sum) => {
  //     setTotalAmount(sum)
  // }

  const getDonationType = () => {
    DonationService.donationType().then((res) => {
      setSpecialRequest(res.filter((item) => item.is_special !== 0));
      setDonationType(res.filter((item) => item.is_special !== 1));
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    DonationService.submitDonation(finalDonationData).then((data) => {
      if (data.payment.status && data.email.status) {
        setIsLoading(false);
        let errorExists;
        if (!data.donor.status) errorExists = true;
        else if (!data.cc_details.status) errorExists = true;
        else errorExists = !data.oneTime.status;

        Swal.fire(
          "Thank you for your donation!",
          `${
            errorExists
              ? "Some information cannot saved successfully but donation done successfully, if it concern do contact Ummah Relief. A verification email has been send to you with details"
              : "A confirmation email has been sent to the email provided."
          }`,
          "success"
        ).then((r) => window.location.reload());
      } else {
        setIsLoading(false);
        let errorMessages;
        if (!data.payment.status) errorMessages = data.payment.message;
        else if (!data.email.status) errorMessages = data.email.message;

        Swal.fire({
          icon: "error",
          title: "Error occurred",
          html: ErrorFormat.validationErrors(errorMessages),
        });
      }
    });
  };

  useEffect(() => {
    document.title = "One Time Donations | Ummah relief";
    getDonationType();
  }, []);

  useEffect(() => {
    setFinalDonationData({
      personal: personalInfo,
      card: creditCardInfo,
      donations: totalDonation,
      donation_of: {
        oneTime: true,
        monthly: false,
        zakat_fitra_donation: false,
        qurbani: false,
        cause: false,
        special: false,
      },
      payment_through: "stripe",
      currency: currency,
      duration_id: 1,
    });
    // handleSum(totalAmount)
    if (
      FormValidator.requiredFieldCheck(finalDonationData, "oneTime").includes(
        false
      )
    ) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
    // console.log(totalAmount)
  }, [totalDonation, personalInfo, creditCardInfo, currency]);

  const isValidated = (values) => {
    if (typeof values === "object") {
      return Object.values(values).every(
        (x) => x !== null || x !== "" || x !== 0
      );
    } else {
      return values !== "" || values !== null;
    }
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-12">
            <SpecialRequestForm
              options={specialRequest}
              handleSum={(sum) => setTotalAmount(sum)}
              setTotalDonation={(total) => setTotalDonation(total)}
              totalDonation={totalDonation}
              totalAmount={totalAmount}
            />
            <DonationForm
              donationOption={donationType}
              handleSum={(sum) => setTotalAmount(sum)}
              totalAmount={totalAmount}
              setTotalDonation={(total) => setTotalDonation(total)}
              totalDonation={totalDonation}
              title="One Time Donation"
            />
            <h4 className="fw-bold">Total: ${totalAmount}</h4>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12">
            <PersonalInfoForm
              personalInfo={personalInfo}
              setPersonalInfo={(info) => setPersonalInfo(info)}
            />
            <CreditCardForm
              creditCardInfo={creditCardInfo}
              setCreditCardInfo={(creditCard) => setCreditCardInfo(creditCard)}
            />
            <div className="d-flex justify-content-end gap-3">
              {/*<button className="btn btn-secondary btn-lg" style={{borderRadius: 30}}>Reset</button>*/}
              <button
                className="btn btn-success btn-lg"
                style={{ borderRadius: 30 }}
                disabled={isLoading}
                // onClick={() => handleSubmit()}
                type="submit"
              >
                {isLoading ? "Processing..." : "Donate Now"}
              </button>
            </div>
            <div className="w-100 py-3">
              <img
                src={DonnationPoster}
                className="w-100"
                alt="Ummah Relief Donation"
              />
              <p className="text-dark">
                Ummah Relief International is a U.S. based non-profit
                organization established in 1994. Tax ID# 36-3954960, Not for
                Profit 501(c)3. All your donations are tax deductible.
              </p>
              <p className="text-muted">
                * Ummah Relief International may use its discretion to redirect
                donations in an emergency to where the need is greatest
              </p>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default OneTimeDonation;
