import React, {useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const AboutSection = () => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    return (
        <>
            <div className="container my-5">
                <div
                    className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row gap-5 justify-content-between">
                    <div className="w-100">
                        <h2 className="fw-bold mb-3">About Us</h2>
                        <p>
                            Ummah Relief International was established in 1994 to help needy people around the world. It
                            is
                            a dedicated relief organization providing a variety of humanitarian services such as food,
                            clothing, medical treatment, and schools. It brings help and hope to thousands of victims of
                            natural and human disasters.
                            <br/>
                            <br/>
                            Each year, millions of men, women, and children are impacted by floods, earthquakes,
                            tornadoes,
                            hurricanes and other disasters. Everyday Ummah Relief International Humanitarian Relief
                            programs
                            help people relieve some of the suffering in the world. With your help, Ummah Relief
                            International responds to natural disasters all over the world with quick and targeted
                            action.
                        </p>
                    </div>
                    <div className="w-100 d-none d-sm-block d-md-block d-lg-block">
                        <div className="position-relative">
                            <span
                                className="position-absolute top-0 bg-success d-flex justify-content-center align-items-center text-white fw-bold px-5"
                                style={{width: '100%', height: 315, fontSize: 25, textAlign: "center"}}>
                                <span>Click here to view Interview of Ummah Relief's President</span>
                            </span>
                            <div
                                className="position-absolute top-0 d-flex justify-content-center align-items-center text-white bg-white"
                                onClick={toggle}
                                style={{
                                    width: '100%',
                                    height: 315,
                                    fontSize: 25,
                                    textAlign: "center",
                                    opacity: 0.5,
                                    cursor: 'pointer'
                                }}>
                                <i className="fa-solid fa-play text-dark p-5"
                                   style={{
                                       fontSize: 50,
                                       position: 'absolute',
                                       zIndex: 999,
                                       border: '3px solid #fff',
                                       borderRadius: '50%',

                                   }}></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal isOpen={modal} size="lg" toggle={toggle}>
                <ModalHeader toggle={toggle}>Interview</ModalHeader>
                <ModalBody className="d-flex justify-content-center align-items-center">
                    <iframe width="700" style={{height: '50vh'}} src="https://www.youtube.com/embed/xsEEEjsyZgM"
                            allowFullScreen></iframe>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default AboutSection;