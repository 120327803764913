import React from 'react';

const ContactMap = () => {
    return (
        <section className="contact-map-area">
            <div className="container-fluid pl-0 pr-0">
                <div className="row no-gutters">
                    <div className="col-xl-12">
                        <div
                            className="map-area map-02  wow fadeInUp2  animated"
                            data-wow-delay=".1s"
                            style={{
                                visibility: "visible",
                                animationDelay: "0.1s",
                                animationName: "fadeInUp2"
                            }}
                        >
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2963.046346505027!2d-88.312902!3d42.0421865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f053995313aff%3A0x27b6d28344b1f91f!2s311%20Heine%20Ave%2C%20Elgin%2C%20IL%2060123%2C%20USA!5e0!3m2!1sen!2s!4v1664457599129!5m2!1sen!2s"
                                width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default ContactMap;