import React from 'react';
import moment from "moment";
import {Link} from "react-router-dom";
import LinesEllipsis from 'react-lines-ellipsis'
import {FavIcon} from "../../../../utils/Images";

const BlogCard = ({blog}) => {
    return (
        <div
            className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp2  animated"
            data-wow-delay=".1s"
            style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp2"
            }}
        >
            <div className="blogs mb-30">
                <div className="blogs__thumb pos-rel mb-45">
                    <div className="blogs__thumb--img">
                        {/*<img src={`data:image/png;base64, ${blog.image_base64}`}*/}
                        {/*     style={{width: '100%', minHeight: '250px', maxHeight: '250px'}} alt=""/>*/}
                        <img src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/blog/${blog.image}`}
                             style={{width: '100%', minHeight: '250px', maxHeight: '250px'}}
                             alt="causes"/>
                    </div>
                    <a className="blog-tag text-decoration-none">
                        {blog.categories.category}
                    </a>
                </div>
                <div className="blogs__content">
                    <div className="blogs__content--meta mb-15">
                        <span>
                            <i className="far fa-calendar-alt"/> {moment(blog.created_at).format('DD MMM YYYY')}
                        </span>
                        <span>
                            <i className="far fa-comment"/> Comment ({blog.comment_count})
                        </span>
                    </div>
                    {/*style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}*/}
                    <h4 className="mb-20">
                        <Link to={`/blogs/details/${blog.id}`} className="text-decoration-none">
                            <LinesEllipsis
                                text={blog.title}
                                maxLine='1'
                                ellipsis='...'
                                trimRight
                                basedOn='letters'
                            />
                        </Link>
                    </h4>
                    <ul className="project-manager p-0">
                        <li>
                            <img src={FavIcon} alt="" width={25} height={25}/>
                            <span className="mx-2">Admin</span>
                        </li>
                        <li>
                            <Link className="more_btn_02 text-decoration-none" to={`/blogs/details/${blog.id}`}>
                                Read more <i className="fas fa-arrow-right"/>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    );
};

export default BlogCard;