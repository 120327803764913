import React, {useEffect} from 'react';

const SpecialRequestForm = ({options, handleSum, totalAmount, setTotalDonation, totalDonation}) => {

    const handleRadioClick = (id, title, value) => {
        const dataObj = {
            donation_type_id: id,
            title: title,
            amount: value,
            is_special_request: 1
        }
        totalDonation.forEach((item, index) => {
            if (totalDonation && item.donation_type_id === dataObj.donation_type_id) {
                totalDonation.splice(index, 1);
            }
        })
        setTotalDonation(old => [...old, dataObj])
    }

    const handInputChange = (id, e, title) => {
        const dataObj = {
            donation_type_id: id,
            title: title,
            amount: e.target.value,
            is_special_request: 1
        }
        totalDonation.forEach((item, index) => {
            if (totalDonation && item.donation_type_id === dataObj.donation_type_id) {
                totalDonation.splice(index, 1);
            }
        })
        setTotalDonation(old => [...old, dataObj])
    }

    function handleInputFocus(e, id) {
        const option1 = document.getElementById(`option_1_${id}`)
        const option2 = document.getElementById(`option_2_${id}`)
        const option3 = document.getElementById(`option_3_${id}`)
        totalDonation.forEach((item, index) => {
            if (totalDonation && item.donation_type_id === id) {
                totalDonation.splice(index, 1);
            }
        })
        option1.checked = false
        option2.checked = false
        option3.value = ""
    }

    const getSum = () => {
        return totalDonation.reduce(function (sum, current) {
            return sum + parseInt(current.amount ? current.amount : 0);
        }, 0);
    }

    useEffect(() => {
        if (handleSum !== '' && handleSum !== undefined) {
            let total = totalAmount + getSum()
            handleSum(total)
        }
    }, [totalDonation])

    return (
        <>
            <h4 className="mb-2">Special Request</h4>
            <div className="card border-0 pt-3" style={{backgroundColor: '#adb5bd'}}>
                <div className="card-body">

                    {options && options.map((item, index) => (
                        <div key={index}
                             className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row w-full justify-content-between align-items-center gap-3 border-bottom pb-2 mb-2">
                            <div className="donationTitle">
                                <strong>{item.title}</strong>
                            </div>
                            <div
                                className="d-flex flex-column flex-md-row flex-lg-row donationOptions justify-content-center align-items-center gap-3">
                                <table className="table table-borderless p-0">
                                    <tbody>
                                    <tr>
                                        <td className="border-0 p-0">
                                            <div className="form-check pt-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={item.id}
                                                    id={`option_1_${item.id}`}
                                                    defaultChecked={false}
                                                    onChange={(e) =>
                                                        handleRadioClick(item.id, item.title, item.option1)
                                                    }
                                                />
                                                <label
                                                    className="form-check-label text-dark"
                                                    htmlFor={`option_1_${item.id}`}
                                                >
                                                    <span className="px-1">$</span>
                                                    {item.option1}
                                                </label>
                                            </div>
                                        </td>
                                        <td className="border-0 p-0">
                                            <div className="form-check pt-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={item.id}
                                                    id={`option_2_${item.id}`}
                                                    defaultChecked={false}
                                                    onChange={(e) =>
                                                        handleRadioClick(item.id, item.title, item.option2)
                                                    }
                                                />
                                                <label
                                                    className="form-check-label text-dark"
                                                    htmlFor={`option_2_${item.id}`}
                                                >
                                                    <span className="px-1">$</span>
                                                    {item.option2}
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="form-group d-flex justify-content-end align-items-center pt-2">
                                    <label htmlFor={`option_3_${item.id}`}
                                           className="form-label text-dark pt-2 mx-3"
                                           style={{width: '50%', textAlign: 'left'}}
                                    >
                                        Other $
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="0"
                                        id={`option_3_${item.id}`}
                                        min="0"

                                        onFocus={(e) => handleInputFocus(e, item.id)}
                                        onChange={(e) =>
                                            handInputChange(item.id, e, item.title)
                                        }
                                        style={{width: '70%', textAlign: 'right'}}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default SpecialRequestForm;