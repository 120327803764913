import React, {useEffect} from 'react';
import {PageHeader} from "../../components";
import {BlogList} from "./components/BlogsComponent";

const breadcrumbItems = [
    {
        title: 'Home',
        route: '/',
        isLast: false
    },
    {
        title: 'Blogs',
        route: `/blogs/1`,
        isLast: true
    }
]

const Blogs = () => {
    useEffect(() => {
        document.title = "Blogs | Ummah relief"
    }, [])
    return (
        <>
            <PageHeader breadcrumbItems={breadcrumbItems} title="All Blogs"/>
            <BlogList />
        </>
    );
};

export default Blogs;