import React, {useState, useEffect} from 'react';
import '../CauseDetailComponent/customStyle.css'
import {PaginationComponent} from "../../../../components";
import {useParams} from "react-router-dom";
import BlogService from "../../../../services/blogs.service";
import BlogCard from "./BlogCard";
import {LogoLoader} from "../../../../utils/Images";

const BlogList = () => {
    const [blogs, setBlogs] = useState([])
    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(0)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const params = useParams()
    const getAllBlogs = () => {
        setBlogs([])
        BlogService.getBlogs(params.pageNo)
            .then(res => {
                // console.log(res)
                setBlogs(res.data)
                setFrom(res.from)
                setTo(res.to)
                setTotal(res.total)
                setPerPage(res.per_page)
            })
    }

    useEffect(() => {
        getAllBlogs()
    }, [params])
    return (
        <section className="blog-area pt-130 pb-100">
            <div className="container">
                {blogs.length === 0
                    ? <div className="d-flex flex-column justify-content-center align-items-center">
                        <img src={LogoLoader} alt=""/>
                        <h3>Loading...</h3>
                    </div>
                    : <>
                        <div className="row">
                            {blogs.map((blog, index) => (
                                <BlogCard blog={blog} key={index}/>
                            ))}
                            <div
                                className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-between align-items-center">
                                <span>Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{total}</strong> entries</span>
                                <PaginationComponent route="blogs" total={total / perPage}/>
                            </div>
                        </div>
                    </>}
            </div>
        </section>

    );
};

export default BlogList;