import React from 'react';
import {themeConfig} from "../../../../config/theme.config";
import {Card, CardBody} from "reactstrap";
import {HandHoldingDollar, LocationPin, PhoneCall, ZelleLogo} from "../../../../utils/Images";

const DonationWaysSeparator = () => {
    return (
        <div className="mt-5" style={{backgroundColor: themeConfig.green}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-12 my-4">
                        <Card className="border-0 rounded-0 text-white" color="transparent">
                            <CardBody className="py-4">
                                <div
                                    className="d-flex flex-column justify-content-start align-items-center gap-4 px-2">
                                    <div className="px-4 text-white">
                                        <img src={ZelleLogo} alt="" width={100}/>
                                    </div>
                                    <div className="d-flex flex-column align-items-start w-100 align-items-center">
                                        <h4 className="fw-bold text-center text-white">Donate By Zelle</h4>
                                        <p className="text-center text-white">You can make a donation by Zelle
                                            send your donations <br/>
                                            to: <a className="text-white"
                                                   href="mailto: ummah@ummahrelief.org"> ummah@ummahrelief.org</a>
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-4">
                        <Card className="border-0 rounded-0 text-white" color="transparent">
                            <CardBody className="py-4">
                                <div
                                    className="d-flex flex-column justify-content-start align-items-center gap-3 px-2">
                                    <div className="px-4 text-white">
                                        <img src={PhoneCall} alt="" width={50}/>
                                    </div>
                                    <div className="d-flex flex-column align-items-start w-100 align-items-center">
                                        <h4 className="fw-bold text-center text-white">Donate By Phone</h4>
                                        <p className="text-center text-white">
                                            You can make a donation by telephone <br/>
                                            Phone: <a className="text-white"
                                                      href="tel:1-800-713-4482">1-800-713-4482</a> <br/>
                                            Cell: 8<a className="text-white" href="tel:847-622-0574">847-622-0574</a>
                                            <br/>
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-4">
                        <Card className="border-0 rounded-0 text-white" color="transparent">
                            <CardBody className="py-4">
                                <div
                                    className="d-flex flex-column justify-content-start align-items-center gap-3 px-2">
                                    <div className="px-4 text-white">
                                        <img src={HandHoldingDollar} alt="" width={50}/>
                                    </div>
                                    <div className="d-flex flex-column align-items-start w-100 align-items-center">
                                        <h4 className="fw-bold text-center text-white">Calculate & Pay Zakat Online</h4>
                                        <p className="text-center text-white">
                                            By donating Zakat/Sadaqa we can distribute Food packages to the most needy
                                            people. Your Online Zakat Donation is needed.
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-4">
                        <Card className="border-0 rounded-0 text-white" color="transparent">
                            <CardBody className="py-4">
                                <div
                                    className="d-flex flex-column justify-content-start align-items-center gap-3 px-2">
                                    <div className="px-4 text-white">
                                        <img src={LocationPin} alt="" width={50}/>
                                    </div>
                                    <div className="d-flex flex-column align-items-start w-100 align-items-center">
                                        <h4 className="fw-bold text-center text-white">By Postal Mail Address</h4>
                                        <p className="text-center text-white">
                                            Ummah Relief International <br/>
                                            P.O. Box 1426 <br/>
                                            Elgin, IL 60121 USA
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DonationWaysSeparator;