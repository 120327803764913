import React, { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment";
import { MaskedInput } from "../../../components";
import FormValidator from "../../../utils/validation";
import CardNumberValidator from "@shaaditech/react-cc-validator";

const CreditCardForm = ({ creditCardInfo, setCreditCardInfo }) => {
  const [paymentType] = useState("stripe");
  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [cscCode, setCSCCode] = useState("");

  const [errors, setErrors] = useState({});
  const [expMonthList, setExpMonthList] = useState([]);

  useEffect(() => {
    const months = moment.months();
    const monthList = months.map((month, index) => {
      return {
        value: index + 1,
        label: month,
      };
    });
    setExpMonthList(monthList);
  }, []);

  useEffect(() => {
    const obj = {
      name: nameOnCard,
      number: cardNumber,
      exp_month: expMonth,
      exp_year: expYear,
      cvc: cscCode,
    };
    if (setCreditCardInfo !== undefined) {
      setCreditCardInfo({ ...obj });
    }
  }, [paymentType, nameOnCard, cardNumber, expMonth, expYear, cscCode]);

  const checkCreditCardExpiration = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    let message = "";


    if (expYear !== null && expMonth !== null) {
      if (expYear == currentYear) {
        if (expMonth < currentMonth) {
          message = "Credit Card is expired";
        } else {
          message = "";
        }
      }
    }
    setErrors((old) => {
      return { ...old, expiryMonth: message };
    });
  };

  useEffect(() => {
    checkCreditCardExpiration();
  }, [expMonth, expYear]);

  return (
    <>
      <h4 className="mt-4">Debit/Credit Card Information</h4>
      <hr />

      <div className="form-group mb-3">
        <label htmlFor="name_on_card" className="form-label text-dark">
          Name on card <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          name="name_on_card"
          onChange={(e) => setNameOnCard(e.target.value.replace(/^\d+$/, ""))}
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          id="name_on_card"
          className="form-control"
          value={nameOnCard}
          placeholder="Name on card *"
        />
        {errors.cardName !== "" && (
          <small className="text-danger">{errors.cardName}</small>
        )}
      </div>

      <div className="form-group mb-3">
        <div>
          <CardNumberValidator>
            {({ isValid, cardType, getInputProps }) => (
              <div>
                <MaskedInput
                  label="Card Number"
                  id="card_number"
                  required={true}
                  mask="9999 9999 9999 9999"
                  name="card_number"
                  min={0}
                  max={19}
                  onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                  onChange={(e) =>
                    setCardNumber(FormValidator.maxLengthCheck(e))
                  }
                  value={cardNumber}
                  {...getInputProps()}
                  cardType={cardType}
                  isValid={isValid}
                />
                {/* <input type="text" {...getInputProps()} /> */}
                {/* <div>{isValid && cardType}</div> */}
                { isValid || (
                  <small className="text-danger">Card number is invalid</small>
                ) }
              </div>
            )}
          </CardNumberValidator>
        </div>
        {/* <MaskedInput
          label="Card Number"
          id="card_number"
          required={true}
          mask="9999 9999 9999 9999"
          name="card_number"
          min={0}
          max={19}
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          onChange={(e) => setCardNumber(FormValidator.maxLengthCheck(e))}
          value={cardNumber}
        />
        {errors.cardNoError !== "" && (
          <small className="text-danger">{errors.cardNoError}</small>
        )} */}
      </div>

      <div className="row mb-3">
        <div className="form-group col-md-6">
          <label htmlFor="exp_month" className="form-label text-dark">
            Expiry Month <span className="text-danger">*</span>
          </label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            defaultValue={expMonthList[0]}
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
            onChange={(e) => setExpMonth(e ? e.value : 0)}
            name="exp_month"
            id="exp_month"
            options={expMonthList}
          />
          {errors.expiryMonth !== "" && (
            <small className="text-danger">{errors.expiryMonth}</small>
          )}
          {(expMonth === 0 || !expMonth) && (
            <small className="text-muted">Expiry Month is required</small>
          )}
        </div>
        <div className="col-md-6">
          <MaskedInput
            label="Expiry Year"
            id="exp_year"
            required={true}
            onBlur={(e) => FormValidator.handleOnBlur(e, setErrors, expMonth)}
            // onInput={(e) => {
            //     if(e.target.value < 2021)
            //         e.target.value = 2021
            // }}
            mask="9999"
            name="exp_year"
            min={2021}
            onChange={(e) => setExpYear(e.target.value)}
            value={expYear}
          />
          {errors.expiryYear !== "" && (
            <small className="text-danger">{errors.expiryYear}</small>
          )}
        </div>
      </div>
      <div className="form-group mb-3">
        <MaskedInput
          label="Card Verification Code"
          id="csc_code"
          required={true}
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          mask="999"
          name="csc_code"
          onChange={(e) => setCSCCode(e.target.value)}
          value={cscCode}
        />
        {errors.cvc !== "" && (
          <small className="text-danger">{errors.cvc}</small>
        )}
      </div>
    </>
  );
};

export default CreditCardForm;
