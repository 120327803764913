import React from 'react';
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {themeConfig} from "../config/theme.config";
import {Link} from "react-router-dom";

const PageHeader = ({title, breadcrumbItems}) => {
    return (
        <div style={{backgroundColor: themeConfig.orange}}>
            <div className="container d-flex justify-content-between align-items-center text-white py-5 flex-column flex-sm-column flex-md-row flex-lg-row">
                <h2 className="text-white fw-bold">{title}</h2>
                <Breadcrumb>
                    {breadcrumbItems.map((breadcrumb, index) => (
                        !breadcrumb.isLast
                            ? <BreadcrumbItem active={!!breadcrumb.isLast} key={index}>
                                <Link to={breadcrumb.route} className="text-white">
                                    {breadcrumb.title}
                                </Link>
                            </BreadcrumbItem>
                            : <BreadcrumbItem active={!!breadcrumb.isLast} className="text-white" key={index}>
                                    {breadcrumb.title}
                            </BreadcrumbItem>
                    ))}
                </Breadcrumb>
            </div>
        </div>
    );
};

export default PageHeader;